import { Close } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonIcon from "@mui/icons-material/Person";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { uiLoggerNamesChecklistAndNotes } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import "../Checklist/Checklist.scss";

const NotesModal = ({
  openNotesModal,
  setOpenNotesModal,
  selectedNotesTags,
  setSelectedNotesTags,
  setOpenAddNoteModal,
  tagsList,
  listOfNotes,
  isViewNotesListRef,
  setNotesTitle,
  setSelectedNotesTagsForAdd,
  setNotesDynamicContent,
  deleteNote,
  selectedNotesRef,
  companyId,
  branchCode,
  businessPartnerSelectedRef,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDeleteNotes, setOpenDeleteNotes] = useState(false);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setOpenDeleteNotes(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setSelectedNotesTags([]);
    setOpenNotesModal(false);
  };

  const cancelDeleteNotes = () => {
    setOpenDeleteNotes(false);
  };

  return (
    <div>
      <Dialog open={openNotesModal} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">
            Notes{" "}
            <span className="ml_8 vertical_center_align">
              <HelpOutlineIcon />
            </span>
          </div>
          <div className="vertical_center_align">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers sx={{ pt: 2, height: "85vh", backgroundColor: "#F3F3F3" }}>
          <Grid style={{ height: "100%" }}>
            <Grid>
              <Autocomplete
                className="update-custom-border-radius"
                size="small"
                multiple
                id="tags-outlined"
                options={tagsList}
                getOptionLabel={(option) => option}
                value={selectedNotesTags} // Controlled by state
                onChange={(_event, newValue) => {
                  uiLogger(
                    uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_SEARCH_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_SEARCH_CLICK.message,
                      businessPartnerId: businessPartnerSelectedRef.current,
                    }
                  );
                  setSelectedNotesTags(newValue); // Update state when selection changes
                }}
                filterSelectedOptions
                renderInput={(params) => <TextField {...params} label="Search by tags" />}
                renderOption={(props, option, { index }) => (
                  <React.Fragment key={`${option}-${index}`}>
                    <li {...props}>{option}</li>
                    {selectedNotesTags.includes("Partner Side") && option === "Own Side" && <Divider sx={{ my: 1 }} />}
                    {option === "Partner Side" && <Divider sx={{ my: 1 }} />}
                  </React.Fragment>
                )}
              />
            </Grid>
            <Grid style={{ paddingBottom: "16px" }}>
              {listOfNotes
                ?.filter((note) =>
                  // Check if every selected tag is included in the note's tags
                  selectedNotesTags.every((tag) => note.tags.includes(tag))
                )
                .map((note, index) => (
                  <Card sx={{ marginTop: "16px" }} key={index}>
                    <CardHeader
                      avatar={
                        <Avatar
                          sx={{
                            bgcolor: "#bdbdbdff",
                            width: 32,
                            height: 32,
                            borderRadius: "4px",
                          }}
                        >
                          <PersonIcon />
                        </Avatar>
                      }
                      action={
                        <>
                          <IconButton
                            aria-label="settings"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent the Card onClick from firing
                              handleMenuOpen(event);
                              selectedNotesRef.current = note;
                              setSelectedNotesTagsForAdd(note.tags);
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            sx={{
                              "& .MuiPaper-root": {
                                // Target the Paper component directly
                                borderRadius: "var(--1, 8px)", // Apply the border-radius
                                background: "#FFF", // Set the background color
                                boxShadow: "1px 2px 6px 0px rgba(0, 0, 0, 0.08)", // Set the box shadow
                              },
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleDelete();
                              }}
                            >
                              <ListItemIcon style={{ color: "#FF3E3E" }}>
                                <DeleteIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText style={{ color: "#FF3E3E" }} primary="Delete Note" />
                            </MenuItem>
                          </Menu>
                        </>
                      }
                      title={
                        <Typography variant="body1" sx={{ fontWeight: 400 }}>
                          {note.name}
                        </Typography>
                      }
                      subheader={
                        <Typography variant="body2" color="textSecondary">
                          {moment(note?.createdAt).format("HH:mm, DD/MM/YY")}
                        </Typography>
                      }
                    />
                    <CardContent
                      sx={{
                        cursor: "pointer",
                        paddingTop: "8px",
                        "&:last-child": {
                          paddingBottom: "16px",
                        },
                      }}
                      onClick={() => {
                        isViewNotesListRef.current = true;
                        setSelectedNotesTagsForAdd(note.tags);
                        setNotesTitle(note.title);
                        setNotesDynamicContent(note.content);
                        setOpenNotesModal(false);
                        setOpenAddNoteModal(true);
                        uiLogger(
                          uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_PREVIEW_CLICK.functionName,
                          companyId.current,
                          branchCode.current,
                          {
                            message: uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_PREVIEW_CLICK.message,
                            businessPartnerId: businessPartnerSelectedRef.current,
                          }
                        );
                      }}
                    >
                      <Typography variant="body1" color="textPrimary" paragraph>
                        {note.title}
                      </Typography>
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                        {note.tags.map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag}
                            sx={{
                              bgcolor:
                                tag === "Partner Side" ? "#2196f3ff" : tag === "Own Side" ? "#27B27C" : "#00000014",
                              color: tag === "Partner Side" || tag === "Own Side" ? "white" : "#000000DE",
                            }}
                          />
                        ))}
                      </div>
                    </CardContent>
                  </Card>
                ))}
            </Grid>

            {listOfNotes?.length === 0 && (
              <Grid className="center_align_ver_horiz" style={{ height: "100%" }}>
                <Typography variant="body2" className="checklist_name_empty">
                  Click on ‘+’ button to add a Note
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box sx={{ position: "absolute", bottom: 16, right: 16 }}>
            <Fab
              style={{ background: "#541C4C", color: "#fff" }}
              aria-label="add"
              onClick={() => {
                setSelectedNotesTags([]);
                isViewNotesListRef.current = false;
                setSelectedNotesTagsForAdd(["Partner Side"]);
                setNotesTitle("");
                setNotesDynamicContent("");
                setOpenNotesModal(false);
                setOpenAddNoteModal(true);
              }}
            >
              <AddIcon />
            </Fab>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={openDeleteNotes} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m error-dialog-icon">
            <div>
              <WarningAmberIcon color="error" />
            </div>
            Are you sure you want to Delete this Note?
          </div>
          <div className="vertical_center_align">
            <IconButton
              onClick={() => {
                cancelDeleteNotes();
              }}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogActions className="dialog-actions">
          <CustomButton size="medium" color="error" variant="text" onClick={cancelDeleteNotes}>
            Cancel
          </CustomButton>
          <CustomButton
            size="medium"
            color="error"
            variant="contained"
            onClick={() => {
              cancelDeleteNotes();
              deleteNote();
              uiLogger(
                uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_DELETE_CLICK.functionName,
                companyId.current,
                branchCode.current,
                {
                  message: uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_DELETE_CLICK.message,
                  businessPartnerId: businessPartnerSelectedRef.current,
                }
              );
            }}
          >
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotesModal;
