import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import React, { useContext } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { ManualReconContext } from "./ManualRecon";

const UnreconcileConfirmWarningModal = () => {
  const {
    isOpenUnreconcileConfirmWarningModal,
    setIsOpenUnreconcileConfirmWarningModal,
    unReconcileEntriesOnMatchId,
    showLoadingIconUnreconcile,
    unreconcileRemarks,
    setUnreconcileRemarks,
  } = useContext(ManualReconContext);

  const handleRemarksChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnreconcileRemarks(event.target.value);
  };

  return (
    <div>
      <Dialog open={isOpenUnreconcileConfirmWarningModal} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">Are you sure you want to unreconcile?</div>
          <div className="vertical_center_align">
            <IconButton
              onClick={() => {
                setIsOpenUnreconcileConfirmWarningModal(false);
                setUnreconcileRemarks("");
              }}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 2 }}>
          <TextField
            className="update-custom-border-radius"
            fullWidth
            variant="outlined"
            multiline
            placeholder="Remarks (This will help us improve the tool)"
            value={unreconcileRemarks}
            onChange={handleRemarksChange}
          />
        </DialogContent>
        <DialogActions className="dialog-actions">
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="contained"
            disabled={showLoadingIconUnreconcile}
            onClick={() => {
              unReconcileEntriesOnMatchId();
            }}
            sx={{ marginRight: 1 }}
            startIcon={<LoadingIcon loading={showLoadingIconUnreconcile} />}
          >
            Unreconcile
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnreconcileConfirmWarningModal;
