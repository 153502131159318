import { Close } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { DocumentTypeConfigContext } from "./DocumentTypeConfig";

const DeleteDocTypeSubGrp = () => {
  const {
    isOpenDeleteDocTypeSubGrp,
    setIsOpenDeleteDocTypeSubGrp,
    editGroupEle,
    documentTypeRow,
    setDocumentTypeRow,
    groupsListRef,
  } = useContext(DocumentTypeConfigContext);
  const [showDeleteLoadingIcon, setShowDeleteLoadingIcon] = useState(false);

  const handleCloseDeleteModal = () => {
    setIsOpenDeleteDocTypeSubGrp(false);
  };
  const handleDelete = () => {
    setShowDeleteLoadingIcon(true);
    // Use the filter method to remove the editGroupEle object from documentTypeRow
    const updatedDocumentTypeRow = documentTypeRow.filter((item) => {
      return item.name !== editGroupEle.name || item.group?.groupId !== editGroupEle.group?.groupId;
    });
    setDocumentTypeRow(updatedDocumentTypeRow);
    // Filter out the object that matches the criteria
    groupsListRef.current = groupsListRef.current.filter(
      (group) => !(group.docType === editGroupEle.name && group.groupDetails.groupId === editGroupEle.group.groupId)
    );

    setShowDeleteLoadingIcon(false);
    handleCloseDeleteModal();
  };
  return (
    <div>
      <Dialog open={isOpenDeleteDocTypeSubGrp} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m error-dialog-icon">
            <div>
              <WarningAmberIcon color="error" />
            </div>
            Are you sure
          </div>
          <div className="vertical_center_align">
            <IconButton
              onClick={() => {
                handleCloseDeleteModal();
              }}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <Grid xs={12} md={12}>
            <div>
              <Alert severity="warning">
                Deleting this group would lead to the deletion of the corresponding groups from all partners. Anything
                mapped in the group would be dropped.
              </Alert>
            </div>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <CustomButton onClick={handleCloseDeleteModal} size="medium" color="error" variant="text">
            Cancel
          </CustomButton>
          <CustomButton size="medium" color="error" variant="contained" onClick={handleDelete}>
            <LoadingIcon loading={showDeleteLoadingIcon} />
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteDocTypeSubGrp;
