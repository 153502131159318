import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import LoadingIcon from "../../Common/LoadingIcon";
import { Recon360Context } from "../Recon360";

const ManualChangesConfirmModal = () => {
  const {
    openManualChangesConfirmModal,
    setOpenManualChangesConfirmModal,
    getManuallyChangedEntries,
    businessPartnerSelected,
    companyId,
    branchCode,
    setManualChangesExist,
    getSummaryForReconciliationCommonFunction,
    mappingId,
  } = useContext(Recon360Context);
  const [showLoadingIconApplyBtn, setShowLoadingIconApplyBtn] = useState(false);
  // const [showLoadingIconDontApplyBtn, setShowLoadingIconDontApplyBtn] = useState(true);
  // const [showLoadingIconViewBtn, setShowLoadingIconViewBtn] = useState(true);

  const closeManualChangesConfirmModal = () => {
    setOpenManualChangesConfirmModal(false);
    clearManualChangesData();
  };
  const clearManualChangesData = () => {
    setManualChangesExist(false);
  };
  const preAppliedChanges = async () => {
    setShowLoadingIconApplyBtn(true);
    await useFetch(API_ENDPOINTS.RECON_MANUAL_REAPPLY_PREVIOUS_CHANGES.url, "POST", {
      failureMessage: API_ENDPOINTS.RECON_MANUAL_REAPPLY_PREVIOUS_CHANGES.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        mappingId: mappingId.current,
      },
      thenCallBack: () => {
        setShowLoadingIconApplyBtn(false);
        closeManualChangesConfirmModal();
        getSummaryForReconciliationCommonFunction();
      },
      catchCallBack: () => {
        setShowLoadingIconApplyBtn(false);
      },
    });
  };

  return (
    <div>
      <Dialog open={openManualChangesConfirmModal} maxWidth={DIALOG_MAX_WIDTHS.XS} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">Manual changes?</div>
          <div className="vertical_center_align">
            <IconButton
              onClick={() => {
                closeManualChangesConfirmModal();
                getSummaryForReconciliationCommonFunction();
              }}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers>
          <FormLabel>Do you want to apply the manual changes?</FormLabel>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="text"
            disabled={showLoadingIconApplyBtn}
            onClick={() => {
              closeManualChangesConfirmModal();
              getManuallyChangedEntries();
            }}
          >
            View
          </CustomButton>
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="outlined"
            disabled={showLoadingIconApplyBtn}
            onClick={() => {
              closeManualChangesConfirmModal();
              getSummaryForReconciliationCommonFunction();
            }}
          >
            Don't Apply
          </CustomButton>

          <CustomButton
            size="medium"
            colorToken="violet"
            variant="contained"
            startIcon={<LoadingIcon loading={showLoadingIconApplyBtn} />}
            disabled={showLoadingIconApplyBtn}
            onClick={() => {
              preAppliedChanges();
            }}
          >
            Apply
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ManualChangesConfirmModal;
