import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import LoadingIcon from "../Common/LoadingIcon";
import { logoutUser } from "../Common/logoutUser";
import useFetch from "../Common/useFetch";
import { userContext } from "../Contexts/userContext";
import { NdButton } from "../Recon360/PartnerCommunication/MsmePartnerPortal/CommonComponents";

const MINIMUM_PASSWORD_LENGTH = 8;

export default function CreatePasswordModal({ open, setOpen, setShowAccountSelect, setForm, setIsLoggingInLoading }) {
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isSavingPassword, setIsSavingPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  };

  const validatePassword = (password: string): string => {
    let errorMessage = "";

    if (password.length < MINIMUM_PASSWORD_LENGTH) {
      errorMessage += `Password must be at least ${MINIMUM_PASSWORD_LENGTH} characters long. `;
    }

    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!hasUpperCase) {
      errorMessage += "Password must contain at least one uppercase letter. ";
    }
    if (!hasLowerCase) {
      errorMessage += "Password must contain at least one lowercase letter. ";
    }
    if (!hasNumber) {
      errorMessage += "Password must contain at least one number. ";
    }
    if (!hasSpecialCharacter) {
      errorMessage += "Password must contain at least one special character. ";
    }

    return errorMessage;
  };

  const handleChangePassword = () => {
    const errorMessage = validatePassword(newPassword);
    if (errorMessage !== "") {
      setError(errorMessage);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    setError("");
    setIsSavingPassword(true);
    useFetch(API_ENDPOINTS.FIRST_LOGIN_PASSWORD_CHANGE.url, "POST", {
      failureMessage: API_ENDPOINTS.FIRST_LOGIN_PASSWORD_CHANGE.failureMessage,
      showSuccessToast: true,
      data: {
        password: newPassword,
        userId: actor.userId,
        actorId: actor.id,
      },
      thenCallBack: async () => {
        const isLogoutSuccess = await logoutUser();
        if (isLogoutSuccess) {
          history.push("/");
        }
        setForm({
          companyid: "",
          username: "",
          password: "",
          actorId: 0,
        });
        setIsLoggingInLoading(false);
        setIsSavingPassword(false);
        setOpen(false);
        setShowAccountSelect(false);
      },
      catchCallBack: () => {
        history.push("/");
        setForm({
          companyid: "",
          username: "",
          password: "",
          actorId: 0,
        });
        setIsLoggingInLoading(false);
        setOpen(false);
        setIsSavingPassword(false);
        setShowAccountSelect(false);
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: 3, minWidth: "600px" } }}>
      <Box sx={{ background: "#F5F5F5" }}>
        <DialogTitle className="fw_500 fs_20"> Change password</DialogTitle>
      </Box>
      <Divider />
      <DialogContent>
        <TextField
          type={showPassword ? "text" : "password"}
          label="Create new password"
          value={newPassword}
          onChange={handleNewPasswordChange}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            marginBottom: "16px",
            "& .MuiInputBase-root": {
              borderRadius: "4px",
            },
          }}
        />
        <TextField
          type={showPassword ? "text" : "password"}
          label="Confirm new password"
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-root": {
              borderRadius: "4px",
            },
          }}
        />
        {error && (
          <Alert sx={{ marginTop: 2 }} color="error">
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          onClick={async () => {
            await logoutUser();
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          onClick={handleChangePassword}
          variant="contained"
          sx={{ padding: "8px 22px" }}
          disabled={isSavingPassword}
        >
          <LoadingIcon loading={isSavingPassword} />
          Change Password
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
