import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React, { useState } from "react";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import useFetch from "src/Components/Common/useFetch";
import { a11yProps, TabPanel } from "src/Components/Recon360/ReconSettings/ReconSettings";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";

const PartnerCommunicationAnalytics = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <LoggedInSkeleton topBarButtons={[]}>
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="PC Feedback" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="PC Metrics" {...a11yProps(0)} sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0} key={0}>
            <FeedbackResult />
          </TabPanel>
          <TabPanel value={value} index={1} key={1}>
            <PcMetrics />
          </TabPanel>
        </Box>
      </div>
    </LoggedInSkeleton>
  );
};

export const FeedbackResult = () => {
  const [loading, setLoading] = useState(false);

  const DownloadFeedbackResult = () => {
    setLoading(true);
    useFetch<{ workBookBase64: string }>(API_ENDPOINTS.GET_PARTNER_COMMUNICATION_FEEDBACK_IN_EXCEL.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_PARTNER_COMMUNICATION_FEEDBACK_IN_EXCEL.failureMessage,
      showSuccessToast: true,
      thenCallBack: (_res) => {
        const excelData = Buffer.from(_res.data.workBookBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, `FeedbackResult - ${new Date().toLocaleString()}.xlsx`);
        setLoading(false);
      },
      catchCallBack: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Grid className="center_align fade_in">
      <Button
        variant="contained"
        className="theme_btn"
        sx={{
          m: "30px",
        }}
        onClick={() => DownloadFeedbackResult()}
        disabled={loading}
      >
        {loading ? (
          <span className="file-icon">
            <i className="fas fa-circle-notch fa-spin" />
          </span>
        ) : null}
        Download
      </Button>
    </Grid>
  );
};

export const PcMetrics = () => {
  const [loading, setLoading] = useState(false);

  const DownloadPcMetrics = () => {
    setLoading(true);
    useFetch<{ workBookBase64: string }>(API_ENDPOINTS.GET_PARTNER_COMMUNICATION_METRICS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_PARTNER_COMMUNICATION_METRICS.failureMessage,
      showSuccessToast: true,
      thenCallBack: (_res) => {
        const excelData = Buffer.from(_res.data.workBookBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, "PartnerCommsMetrics.xlsx");
        setLoading(false);
      },
      catchCallBack: () => {
        setLoading(false);
      },
    });
  };

  return (
    <Grid className="center_align fade_in">
      <Button
        variant="contained"
        className="theme_btn"
        sx={{
          m: "30px",
        }}
        onClick={() => DownloadPcMetrics()}
        disabled={loading}
      >
        {loading ? (
          <span className="file-icon">
            <i className="fas fa-circle-notch fa-spin" />
          </span>
        ) : null}
        Download
      </Button>
    </Grid>
  );
};

export default PartnerCommunicationAnalytics;
