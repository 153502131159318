import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import useFetch from "./useFetch";

export const logoutUser = async () => {
  localStorage.clear();
  window.USETIFUL?.user?.setId(null);
  let isLogoutSuccess = false;
  await useFetch(API_ENDPOINTS.LOGOUT.url, "GET", {
    failureMessage: API_ENDPOINTS.LOGOUT.failureMessage,
    thenCallBack: () => {
      isLogoutSuccess = true;
    },
    catchCallBack: () => {
      isLogoutSuccess = false;
    },
  });
  return isLogoutSuccess;
};
