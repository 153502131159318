import { Close } from "@mui/icons-material";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import * as XLSX from "xlsx";
import { UserTypes } from "../../entity/models/FrontendActor";
import { ConfigUseCaseType } from "../../entity/recon-entity/ReconInterfaces";
import { CustomButton } from "../Common/Button/CustomButton";
import LoadingIcon from "../Common/LoadingIcon";
import useFetch from "../Common/useFetch";
import { userContext } from "../Contexts/userContext";
import CustomToast from "../CustomToast";
import PdfTable from "../ReactTable/PdfTable";
import { Recon360Context } from "./Recon360";
import ReconLoader from "./ReconLoader/ReconLoader";

const LedgerPdfUpload = (props: any) => {
  const { fileKeysForPdfNotCorrectMark } = useContext(Recon360Context);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const { actor } = useContext(userContext);
  const headers = Object.keys(props.pdfTableData.JSON[0]);
  const dataList = props.pdfTableData.data;
  // const [selectedPdfConverter, setSelectedPdfConverter] = useState<string>("AWSTextract");
  const { companyId, branchCode } = useContext(Recon360Context);

  const data = props.pdfTableData.JSON.map((item: any) => {
    const temp = item;
    for (const [i, header] of headers.entries()) {
      if (header.includes(".")) {
        temp[header.replace(/\./g, "")] = item[header];
        delete item[header];
        headers.splice(i, 1);
        headers.push(header);
      }
    }
    return temp;
  });

  const columnDefinition = Object.keys(data[0]).map((val) => {
    return {
      accessor: val,
      Sort: true,
      minWidth: 200,
      maxWidth: 200,
      width: 200,
      Cell: ({ value }: any) => <div style={{ width: 200 }}>{value}</div>,
    };
  });

  const downloadUploadedExcel = () => {
    XLSX.writeFile(
      props.pdfTableData.workbook,
      `${props.selectedBusinessPartnerName.split(" ")[0]}-(${props.downloadUploadedPdfName})-(${moment(
        new Date()
      ).format("DD/MM/YYYY")}).xlsx`
    );
  };

  const uploadLedger = async (list: any, own: boolean) => {
    if (own) {
      await useFetch(API_ENDPOINTS.UPLOAD_BUSINESS_PARTNER_LEDGER_FROM_RU.url, "POST", {
        failureMessage: API_ENDPOINTS.UPLOAD_BUSINESS_PARTNER_LEDGER_FROM_RU.failureMessage,
        data: {
          ownLedgerData: list,
          businessPartnerId: props.pdfTableData.businessPartnerId,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        },
        thenCallBack: (response: any) => {
          if (response.data.result.response === "warning-unidentified") {
            toast.warning(<CustomToast message="Unidentified columns present." />);
          } else if (response.data.result.response === "warning-nonEmptyDocTypes") {
            toast.warning(<CustomToast message="Unidentified document types present." />);
          } else if (response.data.result.response === "warning") {
            toast.warning(
              <CustomToast message="File uploaded successfully. Duplicate column headers exist. Please review." />
            );
          } else {
            toast.success(<CustomToast message="File uploaded successfully" />);
            props.setShowUploadedSummaryObject(response.data.result.uploadSummary);
            props.setShowUploadedSummary(true);
          }
          props.setEndDateOwn(new Date());
          props.setEndDateBusinessPartner(new Date());
          props.setUploadedFileName(props.pdfTableData.fileName);
          props.setUploadedLastModifiedDate(
            new Date().getDate() +
              "/" +
              (new Date().getMonth() + 1) +
              "  " +
              new Date().toLocaleTimeString("en-US", {
                // en-US can be set to 'default' to use user's browser settings
                hour: "2-digit",
                minute: "2-digit",
              })
          );

          // props.integration
          //   ? props.getOwnDateSyncFromSAP(props.businessPartnerSelected)
          //   : props.getOwnDataGetReconMetaData(props.businessPartnerSelected);

          props.getReconMetaData("own");

          if (response?.data?.result?.unIdentifiedColumns.length > 0) {
            const columnHeaderArrwithErrorFlag = response.data.result.unIdentifiedColumns.map((object: any) => {
              return { ...object, errorFlag: false, columnHeaderConfigValue: "" };
            });
            props.setColumnHeaderArr(columnHeaderArrwithErrorFlag);
            props.setConfigUserType("Own Ledger");
            props.setConfigUserName(actor?.name);
            props.setOpenColumnHeaderDialog(true);
          }
          if (
            response?.data?.result?.nonEmptyDocTypes.length > 0 &&
            props.headerName.nonRelevantDocTypeFlag === false
          ) {
            props.setDocumentTypesArr(response.data.result.nonEmptyDocTypes);
            props.finaldocumentTypesObj.current = Object.fromEntries(
              response.data.result.nonEmptyDocTypes.map((item: any) => [
                item,
                {
                  documentType: "",
                  transactionType: 0,
                },
              ])
            );

            props.setConfigUserType("Own Ledger");
            props.setConfigUserName(actor?.name);
            if (response?.data?.result?.unIdentifiedColumns.length > 0) {
              props.setOpenDocumentTypeDialog(false);
            } else {
              props.setOpenDocumentTypeDialog(true);
            }
          } else {
            props.setDocumentTypesArr([]);
          }
        },
      });
    } else {
      await useFetch(API_ENDPOINTS.UPLOAD_BUSINESS_PARTNER_LEDGER_FROM_BP.url, "POST", {
        failureMessage: API_ENDPOINTS.UPLOAD_BUSINESS_PARTNER_LEDGER_FROM_BP.failureMessage,
        data: {
          businessPartnerLedgerData: list,
          businessPartnerId: props.pdfTableData.businessPartnerId,
          configUseCaseType:
            props.subTab === "Payment Advice" ? ConfigUseCaseType.paymentAdvice : ConfigUseCaseType.recon,
        },
        thenCallBack: (response: any) => {
          if (response.data.result.response === "warning-unidentified") {
            toast.warning(<CustomToast message="Unidentified columns present." />);
          } else if (response.data.result.response === "warning-nonEmptyDocTypes") {
            toast.warning(<CustomToast message="Unidentified document types present." />);
          } else if (response.data.result.response === "warning") {
            toast.warning(
              <CustomToast message="File uploaded successfully. Duplicate column headers exist. Please review." />
            );
          } else {
            toast.success(<CustomToast message="File uploaded successfully" />);
            props.setShowUploadedSummaryObject(response.data.result.uploadSummary);
            props.setShowUploadedSummary(true);
          }
          props.setEndDateBusinessPartner(new Date());
          props.setUploadedFileName(props.pdfTableData.fileName);
          props.setUploadedLastModifiedDate(
            new Date().getDate() +
              "/" +
              (new Date().getMonth() + 1) +
              "  " +
              new Date().toLocaleTimeString("en-US", {
                // en-US can be set to 'default' to use user's browser settings
                hour: "2-digit",
                minute: "2-digit",
              })
          );

          // props.integration
          //   ? props.getBusinessPartnerDateSyncFromSAP(props.businessPartnerSelected)
          //   : props.getBusinessPartnerDateGetReconMetaData(props.businessPartnerSelected);

          props.getReconMetaData("businessPartner");

          if (response?.data?.result?.unIdentifiedColumns.length > 0) {
            const columnHeaderArrwithErrorFlag = response.data.result.unIdentifiedColumns.map((object: any) => {
              return { ...object, errorFlag: false, columnHeaderConfigValue: "" };
            });
            props.setColumnHeaderArr(columnHeaderArrwithErrorFlag);
            props.setConfigUserType("BusinessPartner Ledger");
            props.setConfigUserName(props.assignBusinessPartnerName);
            props.setOpenColumnHeaderDialog(true);
          }

          if (
            response?.data?.result?.nonEmptyDocTypes.length > 0 &&
            props.headerName.nonRelevantDocTypeFlag === false
          ) {
            props.setDocumentTypesArr(response.data.result.nonEmptyDocTypes);
            props.finaldocumentTypesObj.current = Object.fromEntries(
              response.data.result.nonEmptyDocTypes.map((item: any) => [
                item,
                {
                  documentType: "",
                  transactionType: 0,
                },
              ])
            );

            props.setConfigUserType("BusinessPartner Ledger");
            props.setConfigUserName(props.assignBusinessPartnerName);
            if (response?.data?.result?.unIdentifiedColumns.length > 0) {
              props.setOpenDocumentTypeDialog(false);
            } else {
              props.setOpenDocumentTypeDialog(true);
            }
          } else {
            props.setDocumentTypesArr([]);
          }
        },
      });
    }
  };

  const markPdfIncorrect = () => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.MARK_PDF_INCORRECT.url, "POST", {
      failureMessage: API_ENDPOINTS.MARK_PDF_INCORRECT.failureMessage,
      showSuccessToast: true,
      data: { fileKeys: fileKeysForPdfNotCorrectMark, companyId: companyId.current, branchCode: branchCode.current },
      thenCallBack: () => {
        setShowLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  return (
    <>
      {props.pdfConversionLoader && <ReconLoader />}
      <DialogTitle className="dialog-title">
        <div className="heading-m">Review the file before uploading back</div>

        <div>
          <FormControl>
            <RadioGroup
              row={true}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={props.selectedPdfConverter.current}
              onChange={(e) => {
                props.selectedPdfConverter.current = e.target.value;
                props.setPdfConversionLoader(true);
                props.isPdfOptionConfirm.current = true;
                props.onChangePdfConversionMethod();
              }}
            >
              {!props.disablePdfTableOptionForMultiplePdf && (
                <>
                  <FormControlLabel value="PDFLedgerParser" control={<Radio />} label="Tally" />
                  <FormControlLabel value="AWSTextract" control={<Radio />} label="Option 2" />
                  <FormControlLabel value="PDFTables" control={<Radio />} label="Option 3" />
                </>
              )}
            </RadioGroup>
          </FormControl>
          <IconButton
            onClick={() => {
              props.setOpenPdfDialog(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Grid>
          <p className="note_pdf">
            Note: Highlighted columns headers have been identified, check if the information in column below is correct.
          </p>
        </Grid>
        <Grid>
          <PdfTable
            columns={columnDefinition}
            rows={data}
            sortEnable={false}
            pdfTableHeading={true}
            errorMessage={props?.pdfTableData?.errorMessage}
            highlightedColumns={props?.pdfTableData?.highlightedColumns}
          />
        </Grid>
      </DialogContent>
      <DialogActions className="dialog-actions" style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {false && (
            <CustomButton
              size="medium"
              colorToken="violet"
              variant="contained"
              onClick={() => {
                uploadLedger(dataList, props.pdfTableData.own);
              }}
            >
              Approve
            </CustomButton>
          )}
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="contained"
            onClick={() => {
              downloadUploadedExcel();
            }}
          >
            Download
          </CustomButton>
        </div>
        {actor.userType === UserTypes.CustomerSuccess && (
          <div>
            <Button
              disabled={showLoadingIcon}
              startIcon={<LoadingIcon loading={showLoadingIcon} />}
              className="button"
              style={{ background: "gray", color: "white" }}
              onClick={() => {
                markPdfIncorrect();
              }}
            >
              Mark Incorrect output.
            </Button>
          </div>
        )}
        <div>
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="text"
            onClick={() => {
              props.setOpenPdfDialog(false);
            }}
          >
            Close
          </CustomButton>
        </div>
      </DialogActions>
    </>
  );
};

export default LedgerPdfUpload;
