import { Close } from "@mui/icons-material";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import { Currency } from "dinero.js";
import React, { useContext } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import { amountEntryType } from "src/entity/recon-entity/ReconInterfaces";
import { DefaultCurrency, formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { ledgerUploadName } from "src/Utils/Recon/Recon360/Constants";
import { uiLoggerNamesCBBreakdown } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { Recon360Context } from "../Recon360";
import "./ClosingBalanceBreakdownModal.scss";

const ClosingBalanceBreakdownModal = ({
  open,
  setOpen,
  balance,
  accountingSign,
  name,
  onceFocused,
  setSelectOpen,
  updateClosingBalance,
  Currencies,
  currency,
  accountingTerminology,
  setClosingBalance,
  setClosingBalanceRef,
  setOpenSumOfEntriesModal,
  sumOfEntries,
  openingBalance,
  oldestEntry,
  latestEntry,
  loaderClosingBalanceBreakdown,
  setOpeningBalance,
}) => {
  const { businessPartnerSelectedRef, companyId, branchCode } = useContext(Recon360Context);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">Closing Balance Breakdown</div>
          <div className="vertical_center_align">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 2 }}>
          <Card>
            <CardContent
              sx={{
                p: 0,
                "&:last-child": {
                  paddingBottom: "0", // Override the default padding
                },
              }}
            >
              <Grid>
                <Grid sx={{ pl: 2, pr: 2, background: "#F3F3F3" }}>
                  <Grid item xs={12} container>
                    <Grid item xs={6} className="entry_text">
                      Oldest entry
                    </Grid>
                    <Grid item xs={6} className="text_right entry_text">
                      Latest entry
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container>
                    <Grid item xs={1.8} className="entry_text">
                      {oldestEntry}
                    </Grid>
                    <Grid item xs={8.4} className="entry_text" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flexGrow: 1, borderBottom: "1px dashed grey" }}></div>
                    </Grid>
                    <Grid item xs={1.8} className="text_right entry_text">
                      {latestEntry}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid sx={{ p: 2 }} gap={1}>
                  <Grid item xs={12} container sx={{ pt: 1, pb: 1 }}>
                    <Grid item xs={6} className="entry_text_items">
                      Calculated Opening Balance
                    </Grid>
                    <Grid item xs={6} className="entry_text_items_amt pl_12">
                      {loaderClosingBalanceBreakdown ? (
                        <Skeleton />
                      ) : (
                        `${currency || "INR"}  ${formatMoney(
                          ToDineroObj(Number(openingBalance), currency || "INR"),
                          "en-IN",
                          "0,0"
                        )}`
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container sx={{ pt: 1, pb: 1 }}>
                    <Grid item xs={6} className="entry_text_items">
                      Sum of Entries
                    </Grid>
                    <Grid item xs={6} className="entry_text_items_amt pl_12">
                      {loaderClosingBalanceBreakdown ? (
                        <Skeleton />
                      ) : (
                        `${currency || "INR"}  ${formatMoney(
                          ToDineroObj(Number(sumOfEntries), currency || "INR"),
                          "en-IN",
                          "0,0"
                        )}`
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container sx={{ pt: 1, pb: 1 }}>
                    <Grid item xs={6} className="entry_text_items">
                      Closing Balance
                    </Grid>
                    <Grid item xs={6} className="entry_text_items_amt">
                      <FormControl>
                        {/* <InputLabel htmlFor="outlined-adornment-closingBalance">Closing Balance</InputLabel> */}
                        <MonetaryInput
                          size="small"
                          className="update-custom-border-radius"
                          key={balance}
                          id="outlined-adornment-closingBalance"
                          // label="Closing Balance as per Ledger Uploaded"
                          allowNegative={accountingTerminology !== amountEntryType.debitCredit}
                          value={balance}
                          setValue={(value: number) => {
                            if (accountingTerminology === amountEntryType.debitCredit) {
                              setClosingBalance(accountingSign === "debit" ? value : -1 * value);
                              setClosingBalanceRef.current = accountingSign === "debit" ? value : -1 * value;
                            } else {
                              setClosingBalance(value);
                              setClosingBalanceRef.current = value;
                            }
                            setOpeningBalance(Number(setClosingBalanceRef.current) - Number(sumOfEntries));
                            updateClosingBalance(name === ledgerUploadName.ownSide ? "own" : "businessPartner");
                            uiLogger(
                              name === ledgerUploadName.ownSide
                                ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_SAVE_CLICK.functionName
                                : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_SAVE_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message:
                                  name === ledgerUploadName.ownSide
                                    ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_SAVE_CLICK.message
                                    : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_SAVE_CLICK.message,
                                businessPartnerId: businessPartnerSelectedRef.current,
                              }
                            );
                          }}
                          onBlur={(_e, _v) => {
                            if (!onceFocused) setSelectOpen(true);
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {Currencies.find((cur) => cur.code === currency)?.code || DefaultCurrency.INR}
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            minWidth: 250,
                            borderRadius: 0,
                            "&>.MuiOutlinedInput-root": {
                              p: 0,
                              pl: "12px",
                            },
                          }}
                          currency={(currency as Currency) || "INR"}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Alert
                    className="vertical_center_align"
                    severity="info"
                    action={
                      <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                          uiLogger(
                            name === ledgerUploadName.ownSide
                              ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_KNOW_MORE_CLICK.functionName
                              : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_KNOW_MORE_CLICK.functionName,
                            companyId.current,
                            branchCode.current,
                            {
                              message:
                                name === ledgerUploadName.ownSide
                                  ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_KNOW_MORE_CLICK.message
                                  : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_KNOW_MORE_CLICK.message,
                              businessPartnerId: businessPartnerSelectedRef.current,
                            }
                          );
                          handleClose();
                          setOpenSumOfEntriesModal(true);
                        }}
                      >
                        Know More
                      </Button>
                    }
                  >
                    <span className="alert_text">Discrepancy with Opening Balance or Sum or entries?</span>
                  </Alert>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="contained"
            onClick={() => {
              handleClose();
            }}
          >
            Save
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClosingBalanceBreakdownModal;
