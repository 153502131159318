import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Quill from "quill";
import React, { useState } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { uiLoggerNamesChecklistAndNotes } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import NewEditor from "../../PartnerCommunication/Common/NewEditor";
import "../Checklist/Checklist.scss";

const AddNotesModal = ({
  openAddNoteModal,
  setOpenAddNoteModal,
  isViewNotesListRef,
  notesTitle,
  setNotesTitle,
  tagsList,
  selectedNotesTagsForAdd,
  setSelectedNotesTagsForAdd,
  notesDynamicContent,
  setNotesDynamicContent,
  addNotes,
  setOpenNotesModal,
  addNotesLoader,
  companyId,
  branchCode,
  businessPartnerSelectedRef,
}) => {
  const [, setQuill1] = useState<Quill>(null);
  const maxTitleLength = 100;
  const handleClose = () => {
    setOpenAddNoteModal(false);
    setOpenNotesModal(true);
    isViewNotesListRef.current = false;
  };
  return (
    <div>
      <Dialog open={openAddNoteModal} maxWidth={DIALOG_MAX_WIDTHS.MD} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">{isViewNotesListRef.current ? "Note Preview" : "Add Note"}</div>
          <div className="vertical_center_align">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent dividers sx={{ p: 2 }}>
          <Grid sx={{ mb: 2 }}>
            <Typography className="add_checklist_title">Title*</Typography>
            <Grid className="d_flex">
              <TextField
                className="update-custom-border-radius"
                id="outlined-controlled"
                size="small"
                placeholder="Write anything"
                fullWidth
                value={notesTitle}
                disabled={isViewNotesListRef.current}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  if (event.target.value.length <= 100) {
                    setNotesTitle(event.target.value);
                  }
                }}
              />
            </Grid>
            {!isViewNotesListRef.current && (
              <Typography className="add_checklist_title right_align">
                {notesTitle.length}/{maxTitleLength}
              </Typography>
            )}
          </Grid>
          {!isViewNotesListRef.current && (
            <Grid sx={{ mb: 2 }}>
              <Typography className="add_checklist_title">Add Tag(s)*</Typography>
              <Grid>
                <Autocomplete
                  className="update-custom-border-radius"
                  size="small"
                  multiple
                  id="tags-outlined"
                  options={tagsList}
                  getOptionLabel={(option) => option}
                  value={selectedNotesTagsForAdd} // Controlled by state
                  onChange={(_event, newValue) => {
                    setSelectedNotesTagsForAdd(newValue); // Update state when selection changes
                  }}
                  filterSelectedOptions
                  renderInput={(params) => <TextField {...params} label="Search by tags" />}
                  renderOption={(props, option, { index }) => (
                    <React.Fragment key={`${option}-${index}`}>
                      <li {...props}>{option}</li>
                      {selectedNotesTagsForAdd.includes("Partner Side") && option === "Own Side" && (
                        <Divider sx={{ my: 1 }} />
                      )}
                      {option === "Partner Side" && <Divider sx={{ my: 1 }} />}
                    </React.Fragment>
                  )}
                />
              </Grid>
            </Grid>
          )}
          {isViewNotesListRef.current ? (
            <Grid sx={{ height: "100%" }}>
              <Stack sx={{ gap: 1, height: "100%" }}>
                <iframe
                  srcDoc={notesDynamicContent}
                  referrerPolicy="no-referrer"
                  style={{
                    display: "block",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Stack>
            </Grid>
          ) : (
            <NewEditor content={notesDynamicContent} setContent={setNotesDynamicContent} setQuill={setQuill1} />
          )}
        </DialogContent>
        {!isViewNotesListRef.current && (
          <DialogActions className="dialog-actions">
            <CustomButton
              size="medium"
              colorToken="violet"
              variant="contained"
              disabled={notesTitle?.trim() === "" || selectedNotesTagsForAdd?.length === 0 || addNotesLoader}
              startIcon={<LoadingIcon loading={addNotesLoader} />}
              onClick={() => {
                //   handleClose();
                addNotes();
                uiLogger(
                  uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_ADD_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesChecklistAndNotes.UI_RE_NOTES_ADD_CLICK.message,
                    businessPartnerId: businessPartnerSelectedRef.current,
                  }
                );
              }}
            >
              Add
            </CustomButton>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default AddNotesModal;
