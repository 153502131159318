import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { FileDetails } from "src/entity/recon-entity/ReconInterfaces";
import { Recon360Context } from "../Recon360";

export default function ReuseOldFilesModal() {
  const {
    openReuseOldFilesModal,
    setOpenReuseOldFilesModal,
    ledgerFiles,
    fileDetails,
    isSheetSelectionFinishRef,
    isFileReuploaded,
    disableUploadBothSide,
    excelFileUploadOnChangeFileName,
    uploadSideRef,
    reUploadLedger,
    ignoreDoctypesList,
  } = useContext(Recon360Context);

  return (
    <Dialog open={openReuseOldFilesModal} maxWidth={DIALOG_MAX_WIDTHS.XS} fullWidth>
      <DialogTitle className="dialog-title">
        <div className="heading-m">Re-use Old Files?</div>
        <div className="vertical_center_align">
          <IconButton
            onClick={() => {
              setOpenReuseOldFilesModal(false);
              ledgerFiles.current = [];
              fileDetails.current = {};
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>Do you want to re-use the already uploaded file(s) as per the updated configuration? </FormLabel>
        <div className="mt_10">
          <p className="mb_5">Following files were uploaded during Last Upload.</p>
          <ol type="1">
            {Object.entries(fileDetails.current as FileDetails)?.map(([fileName, values]) =>
              values.map((value) => (
                <li key={`${fileName}_${value}`} className="ml_30">
                  {fileName} {value}
                </li>
              ))
            )}
          </ol>
        </div>
      </DialogContent>
      <DialogActions className="dialog-actions">
        <CustomButton
          size="medium"
          colorToken="violet"
          variant="text"
          onClick={() => {
            setOpenReuseOldFilesModal(false);
            ledgerFiles.current = [];
            fileDetails.current = {};
          }}
        >
          No
        </CustomButton>
        <CustomButton
          size="medium"
          colorToken="violet"
          variant="contained"
          disabled={disableUploadBothSide}
          onClick={() => {
            isSheetSelectionFinishRef.current = true; // dont give again sheet selection modal, use sheet names from server only
            isFileReuploaded.current = true;
            // excelFileUploadOnChange(sortFilesByName(ledgerFiles.current), excelFileUploadOnChangeFileName);
            uploadSideRef.current = excelFileUploadOnChangeFileName;
            ignoreDoctypesList.current = [];
            reUploadLedger("true");
            setTimeout(() => setOpenReuseOldFilesModal(false), 500);
          }}
        >
          Yes
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
}
