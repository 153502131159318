import { ArrowRightRounded, Clear } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { NestedMenuItem } from "mui-nested-menu";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesReportsMailAutomation } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import NewEditor from "../../PartnerCommunication/Common/NewEditor";
import { EmailFields } from "../../PartnerCommunication/Common/ViewReplyMailThread";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { validateEmail } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { EmailInput } from "../AutoCompletes";
import DeleteTemplateConfirmationModal from "./DeleteTemplateConfirmationModal";
import {
  AUTOMATED_MAIL_FREQUENCY_LIST,
  AUTOMATED_MAIL_FREQUENCY_MAP,
  AUTO_MAIL_TEMPLATE_STATUS,
  generateEmailSchedule,
  MIS_MAIL_TYPES,
  TemplateDetails,
} from "./Interfaces";
import LoadingSetupMail from "./LoadingSetupMail";
import moment from "moment";

export default function SetupMail({
  useCaseType,
  currentMailSetupType,
  setCurrentMailSetupType,
  isEditingMailTemplateFlag,
  currentEditingTemplateDetails,
  listAllAutomatedMailTemplates,
}) {
  const [toEmails, setToEmails] = useState<string[]>([]);
  const [ccEmails, setCcEmails] = useState<string[]>([]);
  const [editorContent, setEditorContent] = useState("");
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [emailSubjectError, setEmailSubjectError] = useState<boolean>(false);
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedReportsError, setSelectedReportsError] = useState<boolean>(false);
  const [isSendingMail, setIsSendingMail] = useState<boolean>(false);
  const [allMISReportsOptions, setAllMISReportsOptions] = useState<{ name: string; id: number; reportType: string }[]>(
    []
  );
  const [allAdminReportOptions, setAllAdminReportOptions] = useState<{ name: string; id: number }[]>([]);
  const [statusReportsForDyanmicContent, setStatusReportsForDyanmicContent] = useState<string[]>([]);
  const [funnelReportsForDyanmicContent, setFunnelReportsForDyanmicContent] = useState<string[]>([]);
  const [dynamicContentOptions, setDynamicContentOptions] = useState<string[]>([]);
  const [selectedDyanmicContent, setSelectedDynamicContent] = useState<string>(null);

  const [emailType, setEmailType] = useState(currentMailSetupType);
  const [anchorElReportsDynamicContent, setAnchorElReportsDynamicContent] = useState(null);
  const openReportsDynamicContent = Boolean(anchorElReportsDynamicContent);
  const handleClickReportsDynamicContent = (event) => {
    return setAnchorElReportsDynamicContent(event.currentTarget);
  };
  const handleCloseReportsDynamicContent = () => setAnchorElReportsDynamicContent(null);

  const handleChange = (event: SelectChangeEvent) => {
    setEmailType(event.target.value as string);
  };

  const [automatedEmailName, setAutomatedEmailName] = useState<string>("");
  const [automatedEmailId, setAutomatedEmailId] = useState<number>(null);
  const [automatedEmailNameError, setAutomatedEmailNameError] = useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [startDateErrorText, setStartDateErrorText] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [endDateErrorText, setEndDateErrorText] = useState("");
  const [emailFrequency, setEmailFrequency] = useState<string>(null);
  const [emailFrequencyError, setEmailFrequencyError] = useState<boolean>(false);
  const [isLoadingMailTemplateDetails, setIsLoadingMailTemplateDetails] = useState<boolean>(false);
  const [templateStatus, setTemplateStatus] = useState<AUTO_MAIL_TEMPLATE_STATUS>(AUTO_MAIL_TEMPLATE_STATUS.Active);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);

  useEffect(() => {
    listAllAdminMISReports();
    listAllMISReports();
    listAllDynamicContentOptions();
    if (isEditingMailTemplateFlag) {
      getAutomatedMailTemplateDetails(currentEditingTemplateDetails.id);
    }
  }, []);

  const sendQuickEmail = () => {
    useFetch(API_ENDPOINTS.MAIL_REPORTS_SEND_MAIL.url, "POST", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_SEND_MAIL.failureMessage,
      showSuccessToast: true,
      data: {
        to: toEmails,
        cc: ccEmails,
        subject: emailSubject,
        body: editorContent,
        reports: selectedReports.map((report) => report.id),
      },
      thenCallBack: () => {
        setIsSendingMail(false);
        setCurrentMailSetupType(null);
      },
      catchCallBack: () => {
        setIsSendingMail(false);
      },
    });
  };

  const sendAutomatedEmail = () => {
    const endpoint =
      useCaseType === "MIS"
        ? API_ENDPOINTS.MAIL_REPORTS_SETUP_AUTOMATED_MAIL.url
        : "/api/Admin/MISMail/SaveNewMailTemplate";

    useFetch(endpoint, "POST", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_SETUP_AUTOMATED_MAIL.failureMessage,
      showSuccessToast: true,
      data: {
        name: automatedEmailName,
        id: automatedEmailId,
        to: toEmails,
        cc: ccEmails,
        subject: emailSubject,
        body: editorContent,
        attachedReports: selectedReports.map((report) => report.id),
        startDate: moment(selectedStartDate).format("MM/DD/YYYY"),
        endDate: moment(selectedEndDate).format("MM/DD/YYYY"),
        frequency: emailFrequency,
      },
      thenCallBack: () => {
        setIsSendingMail(false);
        setCurrentMailSetupType(null);
        listAllAutomatedMailTemplates();
      },
      catchCallBack: () => {
        setIsSendingMail(false);
        setCurrentMailSetupType(null);
        listAllAutomatedMailTemplates();
      },
    });
  };
  const handleSendMail = () => {
    let inputsAreInvalid = false;
    if (emailType === MIS_MAIL_TYPES.automatedEmail) {
      if (automatedEmailName?.trim() === "") {
        setAutomatedEmailNameError(true);
        inputsAreInvalid = true;
      }
      if (emailFrequency === null) {
        setEmailFrequencyError(true);
        inputsAreInvalid = true;
      }
      if (startDateErrorText !== "" || endDateErrorText !== "") {
        inputsAreInvalid = true;
      }
      if (inputsAreInvalid) {
        toast.error(<CustomToast message="Invalid Inputs" />);
        return;
      }
      if (selectedStartDate === null) {
        toast.error(<CustomToast message="Start date is required" />);
        return;
      }
      if (selectedEndDate && selectedEndDate) {
        if (selectedEndDate.getTime() < selectedStartDate.getTime()) {
          toast.error(<CustomToast message="End date should be greater than start date" />);
          return;
        }
      }
    }

    if (toEmails.length === 0) {
      toast.error(<CustomToast message="'To' Email is required" />);
      return;
    }
    if (toEmails.some((email) => !validateEmail(email))) {
      toast.error(<CustomToast message="Invalid 'To' Emails" />);
      return;
    }
    if (ccEmails.some((email) => !validateEmail(email))) {
      toast.error(<CustomToast message="Invalid 'cc' Emails" />);
      return;
    }
    try {
      // get the text content from inner html string
      const parser = new DOMParser();
      const doc = parser.parseFromString(editorContent, "text/html");
      const textContent = doc.body.textContent || "";

      if (textContent.trim() === "") {
        toast.error(<CustomToast message={"Email Body Cannot be Empty"} />);
        return;
      }
    } catch (error) {
      toast.error(<CustomToast message={"Invalid Email Body"} />);
      return;
    }

    if (emailSubject.trim() === "") {
      setEmailSubjectError(true);
      inputsAreInvalid = true;
    }
    if (selectedReports.length === 0) {
      setSelectedReportsError(true);
      inputsAreInvalid = true;
    }
    if (inputsAreInvalid) {
      toast.error(<CustomToast message="Invalid Inputs" />);
      return;
    }

    setIsSendingMail(true);
    if (emailType === MIS_MAIL_TYPES.quickEmail) {
      sendQuickEmail();
    } else {
      sendAutomatedEmail();
    }
  };

  const listAllAdminMISReports = () => {
    useFetch("/api/Admin/MIS/ListAllTemplates", "GET", {
      failureMessage: "Failed to fetch MIS Templates",
      thenCallBack: (res) => {
        setAllAdminReportOptions(
          res.data.templateDetails.map((template) => {
            return {
              id: template.templateId,
              name: template.templateName,
            };
          }) || []
        );
      },
    });
  };

  const listAllMISReports = () => {
    useFetch(API_ENDPOINTS.MAIL_REPORTS_GET_REPORTS.url, "GET", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_GET_REPORTS.failureMessage,
      thenCallBack: (res) => {
        const statusReports =
          res.data.statusReports?.map((item) => ({
            name: item.name,
            id: item.id,
            reportType: "Status Reports",
          })) || [];
        const funnelReports =
          res.data.funnelReports?.map((item) => ({
            name: item.name,
            id: item.id,
            reportType: "Funnel Reports",
          })) || [];
        setStatusReportsForDyanmicContent(statusReports.map((item) => `{${item.name}}`));
        setFunnelReportsForDyanmicContent(funnelReports.map((item) => `{${item.name}}`));
        setAllMISReportsOptions([...funnelReports, ...statusReports]);
      },
    });
  };

  const listAllDynamicContentOptions = () => {
    let endpoint = "/api/Admin/MISMail/GetDynamicContents";
    if (useCaseType === "MIS") endpoint = API_ENDPOINTS.MAIL_REPORTS_GET_DYNAMIC_CONTENTS.url;

    useFetch(endpoint, "GET", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_GET_DYNAMIC_CONTENTS.failureMessage,
      thenCallBack: (res) => {
        setDynamicContentOptions(res.data.dynamicContents?.map((item) => `{${item}}`) || []);
      },
    });
  };

  const getAutomatedMailTemplateDetails = (templateId) => {
    setIsLoadingMailTemplateDetails(true);
    const endpoint =
      useCaseType === "MIS"
        ? API_ENDPOINTS.MAIL_REPORTS_GET_AUTOMATED_MAIL_DETAILS(templateId).url
        : `/api/Admin/MISMail/ListMailTemplate/${templateId}`;
    useFetch<TemplateDetails>(endpoint, "GET", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_GET_AUTOMATED_MAIL_DETAILS(templateId).failureMessage,
      thenCallBack: (res) => {
        setSelectedStartDate(res.data.startDate ? new Date(res.data.startDate) : null);
        setSelectedEndDate(res.data.endDate ? new Date(res.data.endDate) : null);
        setEmailFrequency(res.data.frequency);
        setAutomatedEmailName(res.data.name);
        setEditorContent(res.data.body);
        setEmailSubject(res.data.subject);
        setToEmails(res.data.to || []);
        setCcEmails(res.data.cc || []);
        setSelectedReports(res.data.attachedReports || []);
        setTemplateStatus(res.data.status);
        setAutomatedEmailId(res.data.id);
        setIsLoadingMailTemplateDetails(false);
      },
      catchCallBack: () => {
        setCurrentMailSetupType(null);
        setIsLoadingMailTemplateDetails(false);
      },
    });
  };

  const handleChangeSelectTemplate = (value: string) => {
    setSelectedDynamicContent(value);
    if (value !== null) {
      navigator.clipboard.writeText(value);
      setCopyAlert(true);
      setTimeout(() => {
        setCopyAlert(false);
      }, 3000);
    }
  };
  return (
    <>
      {isLoadingMailTemplateDetails ? (
        <LoadingSetupMail />
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              maxWidth: "1000px",
              width: "100%",
              margin: "auto",
              overflow: "scroll",
              maxHeight: "78vh",
            }}
          >
            {templateStatus === AUTO_MAIL_TEMPLATE_STATUS.Error && isEditingMailTemplateFlag && (
              <Alert severity="error" variant="filled">
                All reports added in this mail are deleted and mail cannot be sent.
              </Alert>
            )}
            <Paper
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "#ffffff",
                borderRadius: 1,
                border: "1px solid rgba(0, 0, 0, 0.12)",
              }}
            >
              {useCaseType === "MIS" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: 2,
                    }}
                  >
                    <Typography variant="h6" className="fw_600 fs_18">
                      Email Type
                    </Typography>
                    <Select
                      disabled={isEditingMailTemplateFlag}
                      value={emailType}
                      onChange={handleChange}
                      displayEmpty
                      sx={{
                        width: "200px",
                        borderRadius: "4px !important",
                        "& .MuiSelect-select": {
                          padding: 1.5,
                        },
                      }}
                    >
                      <MenuItem value="Quick Email">Quick Email</MenuItem>
                      <MenuItem value="Automated Email">Automated Email</MenuItem>
                    </Select>
                  </Box>
                  <Divider sx={{ width: "100%" }} />
                </>
              )}
              {emailType === MIS_MAIL_TYPES.automatedEmail && (
                <Stack sx={{ padding: 2, gap: 3, width: "100%" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="overline" className="fw_400 fs_12" color="rgba(0, 0, 0, 0.60)">
                      Automatic Email Name*
                    </Typography>
                    <TextField
                      error={automatedEmailNameError}
                      helperText={automatedEmailNameError && "Email Name is required"}
                      value={automatedEmailName}
                      onChange={(e) => {
                        setAutomatedEmailNameError(false);
                        setAutomatedEmailName(e.target.value);
                      }}
                      variant="outlined"
                      placeholder="Enter Name"
                      size="small"
                      sx={{
                        "& fieldset": {
                          borderRadius: "4px",
                        },
                      }}
                    />
                  </Box>

                  <Typography className="fw_600 fs_18">Set Recurrence</Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    {/* Start Date  */}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="overline" className="fw_400 fs_12" color="rgba(0, 0, 0, 0.60)">
                        START DATE*
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disablePast={isEditingMailTemplateFlag ? false : true}
                          sx={{ "& fieldset": { borderRadius: "4px" } }}
                          onError={(error) => {
                            if (error) setStartDateErrorText("Invalid Date");
                            else setStartDateErrorText("");
                          }}
                          onChange={(date: Date) => {
                            setSelectedStartDate(date);
                          }}
                          value={selectedStartDate}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              helperText: startDateErrorText,
                              sx: { width: "200px" },
                              size: "small",

                              InputProps: {
                                style: { borderRadius: "4px" },
                                startAdornment: (
                                  <InputAdornment position="start" sx={{ margin: 0 }}>
                                    {selectedStartDate && (
                                      <IconButton
                                        size="small"
                                        sx={{ position: "absolute", right: "40px" }}
                                        onClick={() => {
                                          setSelectedStartDate(null);
                                        }}
                                      >
                                        <Clear />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    {/* End Date */}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="overline" className="fw_400 fs_12" color="rgba(0, 0, 0, 0.60)">
                        END DATE (OPTIONAL)
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          disablePast
                          sx={{ "& fieldset": { borderRadius: "4px" } }}
                          onError={(error) => {
                            if (error) setEndDateErrorText(error);
                            else setEndDateErrorText("");
                          }}
                          onChange={(date: Date) => {
                            setSelectedEndDate(date);
                          }}
                          value={selectedEndDate}
                          format="dd/MM/yyyy"
                          slotProps={{
                            textField: {
                              helperText: endDateErrorText && "Invalid Date",
                              sx: { width: "200px" },
                              size: "small",

                              InputProps: {
                                style: { borderRadius: "4px" },
                                startAdornment: (
                                  <InputAdornment position="start" sx={{ margin: 0 }}>
                                    {selectedEndDate && (
                                      <IconButton
                                        size="small"
                                        sx={{ position: "absolute", right: "40px" }}
                                        onClick={() => {
                                          setSelectedEndDate(null);
                                        }}
                                      >
                                        <Clear />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              },
                            },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                      <Typography variant="overline" className="fw_400 fs_12" color="rgba(0, 0, 0, 0.60)">
                        FREQUENCY*
                      </Typography>
                      <FormControl fullWidth error={emailFrequencyError}>
                        <Select
                          error={emailFrequencyError}
                          renderValue={(value: string) => {
                            if (!value) {
                              return <Typography color="gray">Select Frequency</Typography>;
                            }
                            return AUTOMATED_MAIL_FREQUENCY_MAP[value];
                          }}
                          displayEmpty
                          size="small"
                          value={emailFrequency}
                          onChange={(event) => {
                            setEmailFrequencyError(false);
                            setEmailFrequency(event.target.value as string);
                          }}
                          sx={{
                            width: "100%",
                            borderRadius: "4px !important",
                          }}
                        >
                          {AUTOMATED_MAIL_FREQUENCY_LIST.map((item) => (
                            <MenuItem key={item} value={item}>
                              {AUTOMATED_MAIL_FREQUENCY_MAP[item]}
                            </MenuItem>
                          ))}
                        </Select>

                        <FormHelperText>
                          {emailFrequencyError
                            ? "Email Frequency is required"
                            : selectedStartDate &&
                              emailFrequency &&
                              generateEmailSchedule(selectedStartDate, selectedEndDate, emailFrequency)}
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Box>
                  {isEditingMailTemplateFlag && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <Button
                        color="error"
                        className="fw_500 fs_15"
                        onClick={() => {
                          if (useCaseType === "MIS") {
                            uiLogger(
                              uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_DELETE_CLICK
                                .functionName,
                              DEFAULT_COMPANY_ID,
                              DEFAULT_BRANCH_CODE,
                              {
                                message:
                                  uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_DELETE_CLICK.message,
                              }
                            );
                          }
                          setShowDeleteConfirmationModal(true);
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  )}
                </Stack>
              )}
            </Paper>
            <Box sx={{ bgcolor: "#fff" }}>
              <Box sx={{ bgcolor: "#fff", borderBottom: 1, borderColor: "#dedede", padding: 2 }}>
                <Typography variant="h6" className="fw_600 fs_18">
                  Setup Email
                </Typography>
              </Box>

              <Box
                sx={{
                  p: 2,
                  bgcolor: "#f8f8f8",
                  borderBottom: 1,
                  borderColor: "#dedede",
                }}
              >
                <Stack spacing={2}>
                  <EmailFields label="From:" emails={["support@nakad.co"]} isSender />
                  <FormControl>
                    <Box display={"inline-flex"} gap={4} alignItems="center">
                      <Typography variant="subtitle2" sx={{ width: 40, color: "#00000061" }}>
                        To* :
                      </Typography>
                      <EmailInput
                        emails={toEmails}
                        setEmails={setToEmails}
                        label=""
                        limitTags={3}
                        sx={{ width: "100%", "& .MuiOutlinedInput-root": { borderRadius: "4px" } }}
                        placeholder="Add Email"
                      />
                    </Box>
                  </FormControl>

                  <FormControl>
                    <Box display={"inline-flex"} gap={4} alignItems="center">
                      <Typography variant="subtitle2" sx={{ width: 40, color: "#00000061" }}>
                        CC :
                      </Typography>
                      <EmailInput
                        emails={ccEmails}
                        setEmails={setCcEmails}
                        label=""
                        limitTags={3}
                        sx={{ width: "100%", "& .MuiOutlinedInput-root": { borderRadius: "4px" } }}
                        placeholder="Add Email"
                      />
                    </Box>
                  </FormControl>
                </Stack>
              </Box>
              <Box component="form" sx={{ padding: 2, display: "flex", gap: 2, flexDirection: "column" }}>
                <TextField
                  required={true}
                  InputProps={{
                    style: {
                      borderRadius: "4px",
                    },
                  }}
                  error={emailSubjectError}
                  helperText={emailSubjectError && "Email Subject is required"}
                  fullWidth
                  size="small"
                  label="Subject"
                  variant="outlined"
                  value={emailSubject}
                  onChange={(e) => {
                    setEmailSubjectError(false);
                    setEmailSubject(e.target.value);
                  }}
                />
                <NewEditor key="hello" content={editorContent} setContent={setEditorContent} />
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                  <FormControl className="NestedMenuItem update-custom-border-radius">
                    <Select
                      renderValue={(value: string) => {
                        if (value === null) {
                          return <Typography color="gray">Dynamic Content</Typography>;
                        }
                        return value;
                      }}
                      value={selectedDyanmicContent}
                      open={openReportsDynamicContent}
                      onOpen={handleClickReportsDynamicContent}
                      onClose={handleCloseReportsDynamicContent}
                      onChange={null}
                      displayEmpty
                      size="small"
                      style={{ width: "250px" }}
                    >
                      {dynamicContentOptions.map((item) => (
                        <MenuItem
                          key={item}
                          value={item}
                          onClick={() => {
                            handleChangeSelectTemplate(item);
                            handleCloseReportsDynamicContent();
                          }}
                        >
                          {item}
                        </MenuItem>
                      ))}

                      {useCaseType === "MIS" && (
                        <>
                          <NestedMenuItem
                            label={"Status Reports"}
                            parentMenuOpen={openReportsDynamicContent}
                            rightIcon={<ArrowRightRounded />}
                          >
                            {statusReportsForDyanmicContent.length === 0 && (
                              <MenuItem disabled style={{ minWidth: "220px" }}>
                                No Reports
                              </MenuItem>
                            )}
                            {statusReportsForDyanmicContent.map((item) => (
                              <MenuItem
                                key={item}
                                value={item}
                                style={{ minWidth: "220px" }}
                                onClick={() => {
                                  handleChangeSelectTemplate(item);
                                  handleCloseReportsDynamicContent();
                                }}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </NestedMenuItem>
                          <NestedMenuItem
                            label={"Funnel Reports"}
                            parentMenuOpen={openReportsDynamicContent}
                            rightIcon={<ArrowRightRounded />}
                          >
                            {funnelReportsForDyanmicContent.length === 0 && (
                              <MenuItem disabled style={{ minWidth: "220px" }}>
                                No Reports
                              </MenuItem>
                            )}
                            {funnelReportsForDyanmicContent.map((item) => (
                              <MenuItem
                                key={item}
                                value={item}
                                style={{ minWidth: "220px" }}
                                onClick={() => {
                                  handleChangeSelectTemplate(item);
                                  handleCloseReportsDynamicContent();
                                }}
                              >
                                {item}
                              </MenuItem>
                            ))}
                          </NestedMenuItem>
                        </>
                      )}
                    </Select>
                  </FormControl>
                  {copyAlert && (
                    <Alert severity="success" sx={{ paddingY: 0 }}>
                      Copied!
                    </Alert>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px 16px",
                  gap: 3,
                }}
              >
                <Typography
                  variant="subtitle2"
                  className="fs_14 fw_500"
                  sx={{ minWidth: "115px", color: "rgba(0, 0, 0, 0.60)" }}
                >
                  SELECT REPORT
                </Typography>
                <Autocomplete
                  options={useCaseType === "MIS" ? allMISReportsOptions : allAdminReportOptions}
                  groupBy={(option) => {
                    if (useCaseType === "MIS") return option.reportType;
                    else return null;
                  }}
                  limitTags={5}
                  sx={{ "& fieldset": { borderRadius: "4px" } }}
                  value={selectedReports}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedReportsError(false);
                    setSelectedReports(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Select Report"
                      error={selectedReportsError}
                      helperText={selectedReportsError && "Atleast 1 report is required"}
                    />
                  )}
                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  size="small"
                  multiple={true}
                  getOptionLabel={(option) => option.name}
                  filterSelectedOptions={true}
                  renderGroup={(params) => (
                    <li key={params.key}>
                      <Box className="fw_900" sx={{ padding: "4px 10px" }}>
                        {params.group}
                      </Box>
                      <Box>{params.children}</Box>
                    </li>
                  )}
                />
              </Box>
              <Box sx={{ margin: 2, padding: 2, border: "1px solid rgba(0, 0, 0, 0.23)" }}>
                <Typography variant="body1" className="fs_16 fw_400">
                  Thanks & Regards
                </Typography>
                <Typography variant="body1" className="fs_16 fw_400">
                  NAKAD
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#fff", position: "fixed", bottom: 0, left: 0, width: "100%" }}>
            <Box
              sx={{
                width: "90%",
                maxWidth: "1000px",
                margin: "auto",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                padding: 2,
                gap: 2,
              }}
            >
              <Button
                onClick={() => {
                  setCurrentMailSetupType(null);
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={isSendingMail}
                variant="contained"
                className="theme_btn fs_14 fw_500"
                sx={{ borderRadius: "4px !important", padding: "7px 16px" }}
                onClick={() => {
                  let uiLoggerFunctionName;
                  let uiLoggerMessage;
                  if (emailType === MIS_MAIL_TYPES.quickEmail) {
                    uiLoggerFunctionName =
                      uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDQUICKEMAIL_SEND_CLICK.functionName;
                    uiLoggerMessage = uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDQUICKEMAIL_SEND_CLICK.message;
                  } else if (isEditingMailTemplateFlag) {
                    uiLoggerFunctionName =
                      uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_SAVE_CLICK.functionName;
                    uiLoggerMessage =
                      uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_SAVE_CLICK.message;
                  } else {
                    uiLoggerFunctionName =
                      uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDAUTOMATEEMAIL_AUTOMATEEMAIL_CLICK.functionName;
                    uiLoggerMessage =
                      uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_SENDAUTOMATEEMAIL_AUTOMATEEMAIL_CLICK.message;
                  }
                  if (useCaseType === "MIS") {
                    uiLogger(uiLoggerFunctionName, DEFAULT_COMPANY_ID, DEFAULT_BRANCH_CODE, {
                      message: uiLoggerMessage,
                    });
                  }
                  handleSendMail();
                }}
              >
                {isSendingMail && <LoadingIcon loading={true} />}
                {emailType === MIS_MAIL_TYPES.quickEmail
                  ? "SEND EMAIL"
                  : isEditingMailTemplateFlag
                  ? "SAVE"
                  : "AUTOMATE EMAIL"}
              </Button>
            </Box>
          </Box>
        </>
      )}
      {showDeleteConfirmationModal && (
        <DeleteTemplateConfirmationModal
          open={showDeleteConfirmationModal}
          setOpen={setShowDeleteConfirmationModal}
          emailTemplateName={automatedEmailName}
          emailTemplateId={automatedEmailId}
          listAllAutomatedMailTemplates={listAllAutomatedMailTemplates}
          setCurrentMailSetupType={setCurrentMailSetupType}
          useCaseType={useCaseType}
        />
      )}
    </>
  );
}
