import React, { useContext } from "react";
import { useLocation } from "react-router";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getAdminTopBarButtons, getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext"; // only needed for code highlighting
import "./Faq.scss";

const Faq = () => {
  const { actor } = useContext(userContext);
  const location = useLocation<any>();

  return (
    <LoggedInSkeleton
      topBarButtons={
        actor.actorType === "Admin"
          ? getAdminTopBarButtons("Userlist")
          : getReconTopBarButtons("Recon360", actor.name, location?.state?.openCollapseObj, actor)
      }
    >
      <iframe
        src="https://flannel-rest-659.notion.site/ebd/1a11851417d180ec963cdf7c3d052474"
        width="100%"
        height="100%"
      />
    </LoggedInSkeleton>
  );
};

export default Faq;
