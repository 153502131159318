import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import React, { useContext } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { Recon360Context } from "../../Recon360";
import "./LinkOldReco.scss";
import OpeningBalClosingBalSection from "./OpeningBalClosingBalSection";

const UnlinkOldReco = () => {
  const {
    openUnlinkOldReco,
    setOpenUnlinkOldReco,
    selectedTaskId,
    setSelectedTaskId,
    unlinkOldRecoTask,
    deleteCurrentLink,
  } = useContext(Recon360Context);
  const handleClose = () => {
    setOpenUnlinkOldReco(false);
  };
  const handleRadioChange = (taskId: number) => {
    setSelectedTaskId(taskId);
  };

  return (
    <div>
      <Dialog open={openUnlinkOldReco} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">Unlink Old Reconciliation</div>
          <div className="vertical_center_align">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 2 }}>
          <OpeningBalClosingBalSection linkingData={unlinkOldRecoTask} selectedTaskId={selectedTaskId} />
          <div className="task-list">
            {unlinkOldRecoTask.map((task) => (
              <ListItem key={task.ticketId} className="task-list-item" onClick={() => handleRadioChange(task.ticketId)}>
                <ListItemIcon>
                  <Radio
                    edge="start"
                    checked={selectedTaskId === task.ticketId}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": `task-list-item-${task.ticketId}` }}
                  />
                </ListItemIcon>
                <div className="task-details">
                  <ListItemText
                    primary={`Task ID: ${task.ticketId}`}
                    secondary={`Reconciliation Period: ${task.startDate} to ${task.endDate}`}
                  />
                </div>
              </ListItem>
            ))}
          </div>
        </DialogContent>
        <DialogActions className="dialog-actions">
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="contained"
            onClick={() => {
              deleteCurrentLink();
              handleClose();
            }}
            disabled={!selectedTaskId} // Disable the button when selectedTaskId is null
          >
            Unlink
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnlinkOldReco;
