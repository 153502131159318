import CloseIcon from "@mui/icons-material/Close";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import Typography from "@mui/material/Typography";
import React, { useContext, useEffect, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { clarity } from "react-microsoft-clarity";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { ConfigPopupAnchorRecon360Context } from "../Transformer/ColumnHeaderConfig";
import DraggableList from "./DraggableList";
import { reorder } from "./helpers";
import "./Sanitizer.scss";

export interface CategoryRules {
  category: string;
  rulesArray: rules[];
}

export type rules = {
  category: string;
  n: boolean;
  rule: string;
  ruleId: number;
  string1: boolean;
  string2: boolean;
};

const TransitionPopup = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SanitizerRules = () => {
  const providerValue = useContext(ConfigPopupAnchorRecon360Context);
  const [rulesWithCategory, setRulesWithCategory] = React.useState([]);
  const [disabledrag, setDisabledrag] = useState<boolean>(true);

  useEffect(() => {
    const changeRuleArray = () => {
      const categoryRules: CategoryRules[] = [];

      const categorySet = new Set();

      providerValue.rules.forEach((rule: rules) => {
        categorySet.add(rule.category);
      });
      categorySet.forEach((val: any) => {
        categoryRules.push({ category: val, rulesArray: [] });
      });

      providerValue.rules.forEach((element: { category: string }) => {
        categoryRules.forEach((element1: { category: string; rulesArray: any[] }) => {
          if (element.category === element1.category) {
            element1.rulesArray.push(element);
          }
        });
      });
      setRulesWithCategory(categoryRules);
    };
    changeRuleArray();
  }, [providerValue.rules]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;
    const draggedRule = providerValue.rulesWithVal[source.index];
    const newRulesWithValue: any[] = [];
    providerValue.rulesWithVal.forEach((element: any, index: number) => {
      if (index === source.index) {
        console.log("sourceIndex");
      } else if (index === destination.index) {
        newRulesWithValue.push(draggedRule);
        newRulesWithValue.push(element);
      } else {
        newRulesWithValue.push(element);
      }
    });

    const newItems = reorder(providerValue.items, source.index, destination.index);
    providerValue.setRulesWithVal(newRulesWithValue);
    providerValue.setItems(newItems);
  };
  return (
    <div>
      <Dialog
        open={providerValue.open}
        maxWidth={DIALOG_MAX_WIDTHS.LG}
        fullWidth
        onClose={() => providerValue.setOpen(false)}
        TransitionComponent={TransitionPopup}
        className="sanitizer_popup"
      >
        <AppBar sx={{ position: "sticky" }} className="themhead">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                providerValue.setOpen(false);
                providerValue.closeSanitizerLogger();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, color: "white" }} variant="h6" component="div">
              Add Rules for {providerValue.labelName}
            </Typography>
            <Button
              autoFocus={true}
              className="theme_btn"
              color="inherit"
              onClick={() => {
                clarity.setTag("Sanitizer", `Close`);
                providerValue.setOpen(false);
                const replaceEmptyRuleItems: any[] = [];
                providerValue.items.map((rule) => {
                  if (rule.n === false && rule.string2 === false && rule.string1 !== true) {
                    replaceEmptyRuleItems.push({
                      ruleId: rule.ruleId,
                      nthIndex: null,
                      text: "",
                      textToReplaceWith: "",
                      n: rule.n,
                      string1: rule.string1,
                      string2: rule.string2,
                    });
                  } else {
                    replaceEmptyRuleItems.push(rule);
                  }
                  return replaceEmptyRuleItems;
                });
                providerValue.saveRules(replaceEmptyRuleItems, providerValue.isDelete);
                providerValue.applyClickSanitizerLogger();
                providerValue.closeSanitizerLogger();
              }}
            >
              Apply
            </Button>
          </Toolbar>
        </AppBar>

        <Container maxWidth="xl">
          <Grid container={true} rowSpacing={4} spacing={4} style={{ marginTop: "10px" }}>
            <Grid item={true} xs={4}>
              <Divider>
                <Chip label="Sanitizer Rules" />
              </Divider>
              {rulesWithCategory &&
                rulesWithCategory.map((val, index) => (
                  <Accordion style={{ marginTop: "20px" }} key={"accordian" + index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id={"panel1bh-header"}
                    >
                      <DoubleArrowIcon color="primary" style={{ color: "#27b27c" }} /> &nbsp; {val.category}
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {val.rulesArray.map((ruleItem: any, _i) => (
                          <React.Fragment key={ruleItem + _i}>
                            <ListItem
                              button={true}
                              onClick={() => {
                                setDisabledrag(true);
                                const newItemArray = [...providerValue.items];
                                newItemArray.push(ruleItem);
                                providerValue.setItems(newItemArray);
                              }}
                            >
                              <Typography>
                                <Icon
                                  baseClassName="fas"
                                  className="fa-plus-circle"
                                  fontSize="small"
                                  color="primary"
                                  style={{ color: "#27b27c" }}
                                />
                                &nbsp;
                                <span>{ruleItem.rule}</span>
                              </Typography>
                            </ListItem>
                            <Divider />
                          </React.Fragment>
                        ))}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Grid>
            <Grid item={true} xs={8} className="rules_window">
              <Divider>
                <Chip label="Selected Sanitizer Rules" />
              </Divider>
              <div className="space_between">
                <h3 style={{ margin: "10px 0" }}>
                  <b className="required"> * </b>required field in the rule
                </h3>
                <div>
                  <Button
                    variant="contained"
                    className="theme_btn"
                    onClick={() => {
                      setDisabledrag(false);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
              <DraggableList onDragEnd={onDragEnd} disabledrag={disabledrag} setDisabledrag={setDisabledrag} />
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
};

export default SanitizerRules;
