import { Close } from "@mui/icons-material";
import {
  Box,
  Chip,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import React, { useContext } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { WhichActionRequiredStatus } from "src/entity/recon-entity/ReconInterfaces";
import arrow_circle_right from "../../../Graphics/arrow_circle_right.svg";
import { Recon360Context } from "../Recon360";

export default function LedgerUploadStatusModal() {
  const {
    openLedgerUploadStatusModal,
    setOpenLedgerUploadStatusModal,
    fileStatus,
    fileNameForUnidentified,
    sheetNameForUnidentified,
    whichActionRequired,
    setOpenColumnHeaderDialog,
    setOpenDocumentTypeDialog,
  } = useContext(Recon360Context);
  return (
    <MuiDialog open={openLedgerUploadStatusModal} maxWidth={DIALOG_MAX_WIDTHS.MD} fullWidth>
      <DialogTitle className="dialog-title">
        <div className="heading-m">Ledger upload status</div>
        <div className="vertical_center_align">
          <IconButton
            onClick={() => {
              setOpenLedgerUploadStatusModal(false);
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers sx={{ display: "flex", flexFlow: "column", gap: 0.5, padding: 0 }}>
        <Grid>
          <Box sx={{ flexGrow: 1, padding: 2 }}>
            <div className="vertical_center_align mb_25">
              <span className="center_align_ver_horiz">
                <img src={arrow_circle_right} alt="" />
              </span>
              <span className="ledger_upload_text ml_5">Ledger upload</span>{" "}
              <span className="ml_5">
                <Chip
                  size="small"
                  label="In-progress"
                  sx={{
                    backgroundColor: "#E59700",
                    color: "#fff",
                  }}
                />
              </span>
            </div>
            <Grid container spacing={2} className="mb_10">
              <Grid item xs={5}>
                <p className="filename_title">Filename</p>
              </Grid>
              <Grid item xs={7}>
                <p className="filename_title">Sheet Names</p>
              </Grid>
            </Grid>
            {Object?.keys(fileStatus).map((filename, index) => (
              <React.Fragment key={index}>
                <Grid container spacing={2} key={index} sx={{ marginBottom: 2 }}>
                  <Grid item xs={5}>
                    <Chip
                      label={filename}
                      variant={filename === fileNameForUnidentified ? "filled" : "outlined"}
                      sx={{
                        borderRadius: "4px",
                        ...(filename === fileNameForUnidentified && {
                          backgroundColor: "#E5F6FD",
                          color: "black",
                        }),
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    {fileStatus[filename].sheetNames.map((sheetName, idx) => (
                      <Chip
                        key={idx}
                        label={sheetName}
                        variant={
                          sheetName === sheetNameForUnidentified && filename === fileNameForUnidentified
                            ? "filled"
                            : "outlined"
                        }
                        sx={{
                          borderRadius: "4px",
                          marginRight: 1,
                          marginBottom: 1,
                          ...(sheetName === sheetNameForUnidentified &&
                            filename === fileNameForUnidentified && {
                              backgroundColor: "#E5F6FD",
                              color: "black",
                            }),
                        }}
                      />
                    ))}
                  </Grid>
                </Grid>
                <Divider sx={{ marginY: 2 }} />
              </React.Fragment>
            ))}
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog-actions" style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="vertical_center_align">
          <span className="center_align_ver_horiz">
            <img src={arrow_circle_right} alt="" />
          </span>{" "}
          <span className="ledger_upload_text ml_5">{whichActionRequired}</span>
          <span className="ml_5">
            {" "}
            <Chip size="small" label="Action Required" color="warning" />
          </span>
        </div>
        <div className="mr_10">
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="contained"
            onClick={() => {
              setOpenLedgerUploadStatusModal(false);
              if (whichActionRequired === WhichActionRequiredStatus.UnIdentifiedColumnHeaders) {
                setOpenColumnHeaderDialog(true);
              } else if (whichActionRequired === WhichActionRequiredStatus.PotentiallyMissedDocumentTypes) {
                setOpenDocumentTypeDialog(true);
              }
            }}
          >
            View
          </CustomButton>
        </div>
      </DialogActions>
    </MuiDialog>
  );
}
