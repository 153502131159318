import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

interface CustomButtonProps extends ButtonProps {
  colorToken?: "violet"; // Define custom prop
}

export const CustomButton = styled(Button)<CustomButtonProps>(({ size, colorToken, variant }) => ({
  textTransform: "capitalize",
  boxShadow: "none",
  borderRadius: "4px !important",
  fontFamily: "var(--font-family)",
  fontWeight: "var(--font-bold)",
  lineHeight: "var(--line-height-130)",
  ...(size === "small" && {
    fontSize: "var(--heading-xs)",
    padding: "2px 12px",
    minHeight: "32px",
    minWidth: "70px",
  }),
  ...(size === "medium" && {
    fontSize: "var(--heading-xs)",
    padding: "4px 16px",
    minHeight: "40px",
    minWidth: "78px",
  }),
  ...(size === "large" && {
    fontSize: "var(--heading-s)",
    padding: "8px 24px",
    minHeight: "48px",
    minWidth: "100px",
  }),

  // Custom colorToken handling (Updated violet Color: #541C4C)
  ...(colorToken === "violet" && {
    ...(variant === "contained" && {
      backgroundColor: "#541C4C",
      color: "#fff",
      "&:hover": { backgroundColor: "#3C1436" }, // Darker shade on hover
    }),
    ...(variant === "outlined" && {
      border: "1px solid #541C4C",
      color: "#541C4C",
      backgroundColor: "transparent",
      "&:hover": { backgroundColor: "rgba(84, 28, 76, 0.04)", border: "1px solid rgba(84, 28, 76, 0.50)" }, // Light violet hover
    }),
    ...(variant === "text" && {
      color: "#541C4C",
      "&:hover": { backgroundColor: "rgba(84, 28, 76, 0.04)" }, // Light violet hover
    }),
  }),
}));
