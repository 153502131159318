import { Close } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { transFormColumnHeader } from "../../../Utils/Recon/Recon360/Constants";
import Table from "../../ReactTable/Table";
import { Recon360Context } from "../Recon360";
import "./UploadedSummary.scss";

const UploadedSummary = (props: any) => {
  const { setWhichActionRequired, cancelPollingRequest } = useContext(Recon360Context);
  const columnDefinitionColumnMapping = React.useMemo(() => {
    let headers: string[] = [];
    if (props.showConfigMappedData?.columnHeader && props.showConfigMappedData?.columnHeader.length > 0) {
      headers = Object.keys(props.showConfigMappedData?.columnHeader[0]);
    }
    return headers.map((header) => ({
      Header: header,
      accessor: (row) => row[header], // Function accessor to handle special characters
      minWidth: 280,
      Cell: ({ value }: any) => <div>{transFormColumnHeader(value)}</div>,
    }));
    // eslint-disable-next-line
  }, [props.showConfigMappedData]);

  const columnDefinitionDocType = React.useMemo(() => {
    let headers: string[] = [];
    if (props.showConfigMappedData?.docType && props.showConfigMappedData?.docType.length > 0) {
      headers = Object.keys(props.showConfigMappedData?.docType[0]);
    }
    return headers.map((header) => ({
      Header: header,
      accessor: (row) => row[header], // Function accessor to handle special characters
      minWidth: 280,
    }));
  }, [props.showConfigMappedData]);

  return (
    <Dialog open={props.showUploadedSummary} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
      <DialogTitle className="dialog-title">
        {props.showConfigMapped ? (
          <div className="heading-m">Configuration Used</div>
        ) : (
          <div className="heading-m">Upload summary</div>
        )}
        <div className="vertical_center_align">
          <IconButton
            onClick={() => {
              props.setShowUploadedSummary(false);
              props.setShowConfigMapped(false);
              setWhichActionRequired(null);
              cancelPollingRequest();
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {props.showConfigMapped ? (
          <div>
            <div>
              <h2 className="configMapHeader">Column mapping used</h2>
              <div>
                <Table
                  columns={columnDefinitionColumnMapping}
                  rows={props.showConfigMappedData?.columnHeader}
                  sortEnable={false}
                  showPagination={false}
                />
              </div>
            </div>
            <div className="mt_30">
              <h2 className="configMapHeader">Doc type mapping used</h2>
              <div>
                <Table
                  columns={columnDefinitionDocType}
                  rows={props.showConfigMappedData?.docType}
                  sortEnable={false}
                  showPagination={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="UploadedSummary">
            <table>
              <thead>
                <tr>
                  <th>Doc type</th>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return (
                      <Tooltip title={k} key={keyInd}>
                        <th>{k?.substring(0, 20) + "..."}</th>
                      </Tooltip>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Overall</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.overall}</td>;
                  })}
                </tr>
                <tr>
                  <td>Invoice</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.invoice}</td>;
                  })}
                </tr>
                <tr>
                  <td>{props.subTab === "Payment Advice" ? "Discount/Rebate" : "Payment"}</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.payment}</td>;
                  })}
                </tr>
                <tr>
                  <td>DN/CN</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.dncn}</td>;
                  })}
                </tr>
                <tr>
                  <td>TDS</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.tds}</td>;
                  })}
                </tr>
                <tr>
                  <td>Reversal</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.reversal}</td>;
                  })}
                </tr>
                <tr>
                  <td>Internal document</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.internalDocument}</td>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </DialogContent>
      <DialogActions className="dialog-actions">
        {props.showConfigMapped ? (
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="text"
            onClick={() => props.setShowConfigMapped(false)}
          >
            View Upload Summary
          </CustomButton>
        ) : (
          <CustomButton
            size="medium"
            colorToken="violet"
            variant="text"
            onClick={() => props.setShowConfigMapped(true)}
          >
            View configuration used
          </CustomButton>
        )}

        <CustomButton
          size="medium"
          colorToken="violet"
          variant="contained"
          onClick={() => {
            props.setShowUploadedSummary(false);
            props.setShowConfigMapped(false);
            setWhichActionRequired(null);
            cancelPollingRequest();
          }}
        >
          Continue
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadedSummary;
