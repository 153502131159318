import { Close } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Dialog, DialogActions, DialogTitle, IconButton } from "@mui/material";
import React from "react";
import { CustomButton } from "src/Components/Common/Button/CustomButton";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";

const DeleteChecklist = ({ openDeleteChecklist, setOpenDeleteChecklist, deleteChecklist }) => {
  const cancelDeleteChecklist = () => {
    setOpenDeleteChecklist(false);
  };

  return (
    <div>
      <Dialog open={openDeleteChecklist} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m error-dialog-icon">
            <div>
              <WarningAmberIcon color="error" />
            </div>
            Are you sure you want to Delete this Checklist?
          </div>
          <div className="vertical_center_align">
            <IconButton
              onClick={() => {
                cancelDeleteChecklist();
              }}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogActions className="dialog-actions">
          <CustomButton size="medium" color="error" variant="text" onClick={cancelDeleteChecklist}>
            Cancel
          </CustomButton>
          <CustomButton
            size="medium"
            color="error"
            variant="contained"
            onClick={() => {
              cancelDeleteChecklist();
              deleteChecklist();
            }}
          >
            Delete
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteChecklist;
