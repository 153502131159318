/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CheckCircle,
  Close,
  Download,
  FilterList,
  FilterListOff,
  MailOutline,
  MoreVert,
  Warning,
} from "@mui/icons-material";
import CachedIcon from "@mui/icons-material/Cached";
import PersonIcon from "@mui/icons-material/Person";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { ClockIcon } from "@mui/x-date-pickers";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import {
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaRes,
  EmailTemplate,
  ListPDFTemplatesResponse,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  ScheduledMailStatusResponse,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import {
  CounterState,
  updateBulkMailedTimeBCB,
  updateEmailResponseMsgBCB,
  updateIsSendingBulkMailsBCB,
  updateMailFailedWorkbookBCB,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { exists } from "src/Utils/Common/Validators";
import { formatDateTime24Hours } from "src/Utils/DateUtils";
import { dynamicContentOptionsBeta } from "src/Utils/PartnerCommunication";
import { getBlankData } from "../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { ManageCommunicationType } from "../BalanceConfirmationBeta/CommunicationTypes/CommunicationTypes";
import PartnerRequests, { AllIssues } from "../BalanceConfirmationBeta/PartnerRequests";
import ReviewCloseAndResetTasksModal from "../BalanceConfirmationBeta/ReviewCloseAndResetTasksModal";
import DownloadReport, { ReportDetailsCustomisationRes } from "../Common/DownloadReport";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  ListCompaniesRes,
} from "../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import { NdAsyncButton, NdButton, NdButtonOutlined } from "../MsmePartnerPortal/CommonComponents";
import { keys, TabPanel } from "./Common/Components";
import FilterAutocomplete from "./Common/FilterAutocomplete";
import ViewReplyMailThread from "./Common/ViewReplyMailThread";
import BcNewContext, { IBcNewContext, TABS_MAP } from "./Components/BcNewContext";
import BalanceConfirmationNewFilter from "./Components/BcNewFilter";
import Mailing from "./Components/Mailing/Mailing";
import { MailSchedulerReportDialog } from "./Components/Mailing/MailSchedulerDialog";
import AllMailsTab from "./Components/Tabs/AllMailsTab";
import ResponseNotReceivedTab from "./Components/Tabs/ResponseNotReceivedTab";
import ResponseReceivedTab from "./Components/Tabs/ResponseReceivedTab";

let intervalId: ReturnType<typeof setInterval> = null;
let newIntervalId: ReturnType<typeof setInterval> = null;

const AuditCommunication = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const dispatch = useDispatch();

  const { isSendingBulkMailsBCB } = useSelector((state: any) => state.bulkMailsStatus as CounterState);

  const [tabValue, setTabValue] = useState(0);
  const [tabChipLabel, setTabChipLabel] = useState<{ [k in keyof typeof TABS_MAP]: number }>({
    responseReceived: 0,
    responseNotReceived: 0,
    allMails: 0,
  });

  const [loader, setLoader] = useState<boolean>(true);
  const [rowsDataBcBeta, setRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>(getBlankData());
  const [storeRowsDataBcBeta, setStoreRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [rowsDataBcNewMailing, setRowsDataBcNewMailing] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [storeRowsDataBcNewMailing, setStoreRowsDataBcNewMailing] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [currentSelectedRow, setCurrentSelectedRow] = useState<BusinessPartnerListForBcBeta>(null);
  const [mailingRows, setMailingRows] = useState<BusinessPartnerListForBcBeta[]>([]);

  // ERP Companies and Branches
  type companyAndBranches = { companyId: string; branches: BranchInfo[] };
  const [listCompanies, setListCompanies] = useState<CompanyInfo[]>([]);
  const [listCompanyAndBranches, setListCompanyAndBranches] = useState<companyAndBranches[]>([]);

  // Internal Page States
  // const [openBcBetaMailThread, setOpenBcBetaMailThread] = useState<boolean>(false);
  const [openBcNewMailing, setOpenBcNewMailing] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [openRaiseReq, setOpenRaiseReq] = useState<boolean>(false);
  const [raisedIssues, setRaisedIssues] = useState<AllIssues[]>([]);
  const [withoutTemplateCheck, setWithoutTemplateCheck] = useState<boolean>(false);

  // Bulk Mailing Error States
  const [openBulkMailReportDialog, setOpenBulkMailReportDialog] = useState<boolean>(false);
  const [bulkEmailStatus, setBulkEmailStatus] = useState<ScheduledMailStatusResponse["bulkEmailStatus"]>({} as any);
  const [mailSentAndTotalCount, setMailSentAndTotalCount] = useState({
    totalPartnerCount: 0,
    mailSentCount: 0,
  });

  // Mail Status Async Refresh States
  const [lastMailSyncStatusDate, setLastMailSyncStatusDate] = useState<Date>(null);
  const [isSyncingMailStatus, setIsSyncingMailStatus] = useState<boolean>(false);
  // const [openStatusRefreshDialog, setOpenStatusRefreshDialog] = useState(false);
  // const [differenceTime, setDifferenceTime] = useState(0);

  const companyId = useRef<string>(DEFAULT_COMPANY_ID);
  const branchCode = useRef<string>(DEFAULT_BRANCH_CODE);

  const storeAllCategories = useRef<string[]>([]);

  const dynamicContentOpts = [...dynamicContentOptionsBeta];

  const {
    openBcBetaMailing: openBcBetaMailThread,
    setOpenBcBetaMailing: setOpenBcBetaMailThread,
    setEmailTemplates,
    setEmailTemplateContent,
    setPdfTemplates,
    setUserEmailSetting,
    setEmailDisplayName,
    setFromEmailAddress,
    setDynamicContentOptions,
  } = useContext(PartnerCommsContext);

  const BcContextValue: IBcNewContext = {
    tabValue,
    setTabValue,
    tabChipLabel,
    setTabChipLabel,
    loader,
    rowsDataBcBeta,
    setRowsDataBcBeta,
    storeRowsDataBcBeta,
    rowsDataBcNewMailing,
    setRowsDataBcNewMailing,
    storeRowsDataBcNewMailing,
    setStoreRowsDataBcNewMailing,
    storeAllCategories,
    currentRow: currentSelectedRow,
    setCurrentRow: setCurrentSelectedRow,
    mailingRows,
    setMailingRows,
    openBcBetaMailThread,
    setOpenBcBetaMailThread,
    openBcNewMailing: openBcNewMailing,
    setOpenBcNewMailing: setOpenBcNewMailing,
    activeStep,
    setActiveStep,
    openRaiseReq,
    setOpenRaiseReq,
    withoutTemplateCheck,
    setWithoutTemplateCheck,
    isSyncingMailStatus,
    setIsSyncingMailStatus,
    lastMailSyncStatusDate,
    setLastMailSyncStatusDate,
    listAllBusinessPartners: () => ListAllBusinessPartners(),
    listUserEmailTemplates: (_name?: string) => ListUserEmailTemplates(_name),
    listAllBusinessPartnerRequest: () => ListAllBusinessPartnerRequest(),
    setIntervalBCBeta: () => setIntervalBCBeta(),
    companyId: companyId.current,
    branchCode: branchCode.current,
    listCompanies,
    listCompanyAndBranches,
    companyIdRef: companyId,
    branchCodeRef: branchCode,
  };

  const fetchData = async () => {
    ListUserEmailTemplates("");
    getListUserEmailSetting();
    // getListUserEmailSetting();
    await ListAllBusinessPartners();
    await ListAllBusinessPartnerRequest();
    await ListDynamicContent();

    if (actor.integration) {
      ListCompanies();
    }
    // await ListAllPresets();
  };

  useEffect(() => {
    fetchData();
    // clear All context state on component mount
    // ResetContext();
    // eslint-disable-next-line
  }, []);

  // Main ROWs API
  const ListAllBusinessPartners = async () => {
    setLoader(true);

    return useFetch<BusinessPartnersListForBcBetaRes>(API_ENDPOINTS.LIST_ALL_MAILED_BUSINESS_PARTNERS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_MAILED_BUSINESS_PARTNERS.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          isIntegrated: actor.integration,
        },
      },
      thenCallBack: (response) => {
        // Extract the businessPartnerId values and store them in a new array
        if (!response.data?.businessPartnerList)
          response.data = { businessPartnerList: getBlankData(), allCategories: ["a", "b", "c"], response: "ok" };
        // storeBcBetaData.current = response.data?.businessPartnerList || [];
        storeAllCategories.current = response.data?.allCategories || [];
        setRowsDataBcBeta(response.data.businessPartnerList || []);
        setStoreRowsDataBcBeta(response.data.businessPartnerList || []);
        setLoader(false);
        // if (Object.keys(appliedFilters).length) setReFilter(true);
        // if (rowsDataBcBeta && defaultTallyCbDate) GetErpClosingBalanceForAllBp(response.data.businessPartnersList);
      },
    });
  };

  // User Email Templates for Mailing
  const ListUserEmailTemplates = async (createTemplateName = "") => {
    return useFetch<ListUserEmailTemplateResponse>(
      API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.failureMessage,
        config: {
          params: {
            templateType: UseCaseType.balanceConfirmationBeta,
            companyId: companyId.current,
          },
        },
        thenCallBack: (response) => {
          const { emailTemplates, lastEmailTemplateSelected } = response.data;
          setEmailTemplates(emailTemplates);
          ListPDFTemplates();

          if (lastEmailTemplateSelected) lastEmailTemplateSelected;
          if (createTemplateName?.trim() !== "") {
            if (response.data?.emailTemplates !== null && response.data?.emailTemplates?.length > 0) {
              const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
                return item.templateName === createTemplateName;
              })[0];
              if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
            }
          }
        },
      }
    );
  };

  // PDF Templates for Mailing
  const ListPDFTemplates = (_createTemplateName = "") => {
    return useFetch<ListPDFTemplatesResponse>(API_ENDPOINTS.LIST_PDF_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_PDF_TEMPLATES.failureMessage,
      config: {
        params: {
          templateType: UseCaseType.balanceConfirmation,
        },
      },
      thenCallBack: (response) => {
        setPdfTemplates(response.data.templates || []);
      },
    });
  };

  // User Display Name and Email Id for Mail Dialog
  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>(API_ENDPOINTS.LIST_USER_EMAIL_SETTING.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_SETTING.failureMessage,
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }

        if (exists(response.data.userEmailSetting?.display_name)) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (exists(response.data.userEmailSetting?.from_email)) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        // if (exists(response.data.userEmailSetting?.signature?.image)) {
        //   setSignatureImgBase64(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        // }
        // if (exists(response.data.userEmailSetting?.signature?.text)) {
        //   setSignature(response.data.userEmailSetting.signature.text);
        // }
      },
    });
  };

  // List Dynamic Contents
  const ListDynamicContent = () => {
    return useFetch<{ dynamicContents: string[] }>(
      API_ENDPOINTS.LIST_DYNAMIC_CONTENT_PARTNER_COMMUNICATION.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_DYNAMIC_CONTENT_PARTNER_COMMUNICATION.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          dynamicContentOpts.push(...res.data.dynamicContents.map((_content) => `{${_content}}`));

          setDynamicContentOptions(dynamicContentOpts);
        },
      }
    );
  };

  const ListCompanies = async () => {
    useFetch<ListCompaniesRes>(API_ENDPOINTS.LIST_COMPANIES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_COMPANIES.failureMessage,
      thenCallBack: (res) => {
        setListCompanies(res?.data?.companies);
        if (actor.branchLevelReconcilation) {
          ListAllBranches(res?.data?.companies.map((c) => c.companyId));
        }
      },
    });
  };

  const ListAllBranches = async (companyIds: string[]) => {
    return useFetch<{ companies: companyAndBranches[] }>("/api/ListBranchesForMultipleCompanies", "GET", {
      failureMessage: "Failed to list branches",
      config: {
        params: {
          companyIds: companyIds,
        },
      },
      thenCallBack: (res) => {
        if (res?.data?.companies) {
          const { companies } = res.data;
          setListCompanyAndBranches(companies);
        }
      },
    });
  };

  // List All Business Partner Request
  const ListAllBusinessPartnerRequest = () => {
    // setLoadingPartnerRequests(true);
    return useFetch<{ requestMap: Record<string, RaisedIssueBc[]> }>(
      API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUESTS.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUESTS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (_res) => {
          // setLoadingPartnerRequests(false);
          const { requestMap } = _res.data;
          if (Object.keys(requestMap)?.length > 0) {
            setRaisedIssues(
              Object.keys(requestMap)?.map((item) => {
                const { businessPartnerName, vendorCode } = JSON.parse(item);
                return {
                  businessPartnerName: businessPartnerName,
                  vendorCode: vendorCode,
                  issues: requestMap[item],
                } as AllIssues;
              })
            );
          } else setRaisedIssues([]);
        },
        catchCallBack: () => {
          // setLoadingPartnerRequests(false);
        },
      }
    );
  };

  // Mark Bp Issue Resolved
  const markIssueResolved = async (issueId: number) => {
    return new Promise<{ success: boolean }>((resolve, reject) =>
      useFetch(API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED.url, "POST", {
        failureMessage: API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED.failureMessage,
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          issueId,
        },
        thenCallBack: (_res) => {
          ListAllBusinessPartnerRequest();
          resolve({ success: true });
        },
        catchCallBack: reject,
        errorCallback: reject,
      })
    );
  };

  // Bulk Mail Status
  const getBulkMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<ScheduledMailStatusResponse>(API_ENDPOINTS.SCHEDULED_BULK_EMAIL_STATUS.url, "GET", {
        failureMessage: API_ENDPOINTS.SCHEDULED_BULK_EMAIL_STATUS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          const { bulkEmailStatus } = res.data;
          if (bulkEmailStatus.isCompleted && bulkEmailStatus.totalPartnerCount === 0) {
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (intervalId !== null) {
              clearInterval(intervalId);
              intervalId = null;
            }
            return;
          }

          setBulkEmailStatus(bulkEmailStatus || ({} as any));
          setMailSentAndTotalCount({
            mailSentCount: bulkEmailStatus.mailSentCount || 0,
            totalPartnerCount: bulkEmailStatus.totalPartnerCount || 0,
          });

          dispatch(updateBulkMailedTimeBCB(bulkEmailStatus.timeStamp));
          // dispatch(updateMailSentPartnerCountBCB(bulkEmailStatus.mailSentCount));
          if (bulkEmailStatus.isCompleted) {
            ListAllBusinessPartners();

            if (bulkEmailStatus?.message !== "") {
              toast.success(<CustomToast message={bulkEmailStatus?.message} />);
            }
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (intervalId !== null) {
              clearInterval(intervalId);
              intervalId = null;
            }
            dispatch(updateIsSendingBulkMailsBCB(false));

            // dispatch(updateMailFailedUsersBCB([]));
          } else {
            dispatch(updateIsSendingBulkMailsBCB(true));
            resolve({ isCompleted: bulkEmailStatus?.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsBCB(false));
          reject();
        },
      });
    });
  };

  const setIntervalBCBeta = () => {
    getBulkMailStatus();
    intervalId = setInterval(async () => {
      const { isCompleted } = await getBulkMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30_000);
  };

  // Bulk Mail Status Start
  useEffect(() => {
    if (actor.integration === false) {
      // getSyncMailStatus();
      if (!intervalId) {
        setIntervalBCBeta();
      } else getBulkMailStatus();

      // if (!newIntervalId) {
      //   setIntervalMailSyncStatus();
      // }
    }
    return () => {
      clearInterval(intervalId);
      intervalId = null;
    };
  }, []);

  const bulkEmailErrorBatches = bulkEmailStatus?.batchStatus?.filter((item) => item.errorReport) || [];
  const bulkEmailInProgressBatches = bulkEmailStatus?.batchStatus?.find((item) => item.status === "In Progress");

  const noInternalPagesOpen = !openBcBetaMailThread && !openBcNewMailing;
  const openBcNewMailPage = openBcNewMailing && !openBcBetaMailThread;

  return (
    <>
      <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
        <BcNewContext.Provider value={BcContextValue}>
          {/* Main Page */}
          {noInternalPagesOpen && (
            <Stack gap={1}>
              {/* Bulk Mailing Bar */}
              {(bulkEmailStatus?.isBulkEmailScheduled || bulkEmailErrorBatches.length > 0) && (
                <Paper className="d_flex" sx={{ width: "100%", px: 2, py: 1 }}>
                  {/* Left Box */}
                  <Box
                    className="left"
                    px={1}
                    gap={2}
                    sx={{ display: "inline-flex", flexGrow: 1, alignItems: "center" }}
                  >
                    <ClockIcon />
                    <Typography variant="subtitle1">
                      Mail Scheduler in progress | {mailSentAndTotalCount.mailSentCount} mails sent out of{" "}
                      {mailSentAndTotalCount.totalPartnerCount}
                    </Typography>
                    <Button
                      variant="outlined"
                      sx={{ borderRadius: "4px !important" }}
                      onClick={() => setOpenBulkMailReportDialog(true)}
                    >
                      View
                    </Button>
                  </Box>
                  {/* Right Box */}
                  {(isSendingBulkMailsBCB || bulkEmailErrorBatches.length > 0) && (
                    <Alert
                      color={
                        isSendingBulkMailsBCB && !bulkEmailErrorBatches?.length
                          ? "info"
                          : bulkEmailErrorBatches?.length > 0
                          ? "error"
                          : "success"
                      }
                      icon={
                        isSendingBulkMailsBCB && !bulkEmailErrorBatches.length ? (
                          <CircularProgress size={16} />
                        ) : bulkEmailErrorBatches?.length > 0 ? (
                          <Warning />
                        ) : (
                          <CheckCircle />
                        )
                      }
                      sx={{
                        display: "inline-flex",
                        py: 0,
                        border: "1px solid #0288D1",
                        borderColor:
                          isSendingBulkMailsBCB && !bulkEmailErrorBatches.length
                            ? "#0288D1"
                            : bulkEmailErrorBatches?.length > 0
                            ? "#D32F2F"
                            : "#2E7D32",
                        alignItems: "center",
                        "& .MuiAlert-message": {
                          py: "6px",
                        },
                      }}
                      action={
                        bulkEmailErrorBatches.length || !isSendingBulkMailsBCB ? (
                          <Box className="right">
                            {bulkEmailErrorBatches?.length > 0 && (
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => {
                                  setOpenBulkMailReportDialog(true);
                                }}
                              >
                                View Issues
                              </Button>
                            )}
                            {!bulkEmailStatus?.isBulkEmailScheduled && (
                              <IconButton
                                size="small"
                                onClick={() => {
                                  dispatch(updateEmailResponseMsgBCB(""));
                                  dispatch(updateMailFailedWorkbookBCB(""));
                                  // dispatch(updateMailSentPartnerCountBCB(0));
                                  dispatch(updateBulkMailedTimeBCB(null));
                                  setBulkEmailStatus({} as any);
                                }}
                                sx={{ ml: "auto" }}
                              >
                                <Close />
                              </IconButton>
                            )}
                          </Box>
                        ) : null
                      }
                    >
                      <Box className="d_flex" sx={{ alignItems: "center" }}>
                        <Typography className="vertical_center_align" fontWeight="medium" fontSize={16}>
                          {isSendingBulkMailsBCB && !bulkEmailErrorBatches.length
                            ? `Sending mail to ${
                                bulkEmailInProgressBatches?.partnerCount || null
                              } Partners | Initiated on: 
                                      ${formatDateTime24Hours(
                                        bulkEmailInProgressBatches?.mailSendTime ||
                                          moment(bulkEmailStatus?.batchStatus?.[0]?.mailSendTime).add(
                                            (bulkEmailInProgressBatches?.batchId - 1) * 2,
                                            "hours"
                                          )
                                      )}`
                            : bulkEmailErrorBatches?.length > 0
                            ? `Action required`
                            : `Sent to ${mailSentAndTotalCount.mailSentCount} Partners out of ${mailSentAndTotalCount.totalPartnerCount}`}
                        </Typography>
                      </Box>
                    </Alert>
                  )}
                </Paper>
              )}

              <HeaderSection
                raisedIssues={raisedIssues}
                bulkEmailStatus={bulkEmailStatus}
                companyIdRef={companyId}
                branchCodeRef={branchCode}
              />

              {/* Main Content */}
              <Card className="d_flex" sx={{ minHeight: 480, flexFlow: "column", borderRadius: "8px !important" }}>
                <TabPanel value={tabValue} index={TABS_MAP.responseReceived.index} transition>
                  <ResponseReceivedTab />
                </TabPanel>
                <TabPanel value={tabValue} index={TABS_MAP.responseNotReceived.index} transition>
                  <ResponseNotReceivedTab />
                </TabPanel>
                <TabPanel value={tabValue} index={TABS_MAP.allMails.index} transition>
                  <AllMailsTab />
                </TabPanel>
              </Card>
            </Stack>
          )}
          {/* <MailThread /> */}
          {openBcBetaMailThread && <ViewReplyMailThread setOpen={setOpenBcBetaMailThread} />}
          {/* <NewMail /> */}
          {openBcNewMailPage && <Mailing setOpen={setOpenBcNewMailing} />}

          <PartnerRequests
            open={openRaiseReq}
            setOpen={setOpenRaiseReq}
            allIssues={raisedIssues}
            markIssueResolved={markIssueResolved}
            setSendSoleOrBulkMail={(_val) => null}
            setWithoutTemplateCheck={setWithoutTemplateCheck}
            rowsDataBcBeta={rowsDataBcBeta}
            useCaseType={UseCaseType.balanceConfirmationBeta}
          />

          {/* Bulk Mailing Error Components */}
          <MailSchedulerReportDialog
            open={openBulkMailReportDialog}
            setOpen={setOpenBulkMailReportDialog}
            bulkEmailStatus={bulkEmailStatus}
          />
        </BcNewContext.Provider>
      </LoggedInSkeleton>
    </>
  );
};

const HeaderSection: React.FC<{
  raisedIssues: AllIssues[];
  bulkEmailStatus: ScheduledMailStatusResponse["bulkEmailStatus"];
  companyIdRef: React.MutableRefObject<string>;
  branchCodeRef: React.MutableRefObject<string>;
}> = ({ raisedIssues, bulkEmailStatus, companyIdRef, branchCodeRef }) => {
  const { actor } = useContext(userContext);
  const {
    companyId,
    branchCode,
    setOpenBcNewMailing,
    mailingRows,
    rowsDataBcBeta,
    setRowsDataBcBeta,
    listAllBusinessPartners,
    listUserEmailTemplates,
    storeRowsDataBcBeta,
    storeAllCategories,
    isSyncingMailStatus,
  } = useContext(BcNewContext);

  // Customization States
  const [customizationSetting, setCustomizationSetting] = useState<ReportDetailsCustomisationRes>({} as any);

  // Top More Menu States
  const [openEditCommsType, setOpenEditCommsType] = useState<boolean>(false);
  const [openDownloadReportDlg, setOpenDownloadReportDlg] = useState<boolean>(false);
  const [isHistoryReport, setIsHistoryReport] = useState<boolean>(false);
  const [downloadBcBetaReportBase64, setDownloadBcBetaReportBase64] = useState<string>("");
  const [showReviewCloseAndResetTasksModal, setShowReviewCloseAndResetTasksModal] = useState<boolean>(false);

  // Filter States
  const [manuallyOpened, setManuallyOpened] = useState(false);
  const [openFilter, setOpenFilter] = useState<boolean>(actor.integration);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string[]>>({});
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [filterObj, setFilterObj] = useState<{ [k in keys]: string[] }>({} as any);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const { isSendingBulkMailsBCB } = useSelector((state: any) => state.bulkMailsStatus as CounterState);

  const ASYNC_INTERVAL_OPs_IN_PROGRESS = isSendingBulkMailsBCB || isSyncingMailStatus;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (filterObj?.company?.length && filterObj?.branch?.length && !manuallyOpened) {
      setTimeout(() => setReFilter(true), 500);
    }
  }, [manuallyOpened, filterObj.company, filterObj.branch, storeRowsDataBcBeta]);

  // Get Report Customization
  const GetReportCustomization = () => {
    return new Promise<ReportDetailsCustomisationRes>((resolve, reject) => {
      useFetch<{ customisationSetting: ReportDetailsCustomisationRes }>(
        API_ENDPOINTS.GET_EXCEL_REPORT_CUSTOMIZATION_SETTING.uri,
        "GET",
        {
          failureMessage: API_ENDPOINTS.GET_EXCEL_REPORT_CUSTOMIZATION_SETTING.failureMessage,
          thenCallBack: (res) => {
            if (res.data?.customisationSetting) {
              setCustomizationSetting(res.data?.customisationSetting);
              resolve(res.data?.customisationSetting);
            }
          },
          catchCallBack: reject,
          errorCallback: reject,
        }
      );
    });
  };

  // Get BC Beta Report
  const getDownloadBcBetaReport = (customization: ReportDetailsCustomisationRes) => {
    return useFetch<{ base64string: string }>(API_ENDPOINTS.GET_BALANCE_CONFIRMATION_REPORT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_BALANCE_CONFIRMATION_REPORT.failureMessage,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        customisation: customization,
        isIntegrated: actor.integration,
      },
      thenCallBack: (response) => {
        setDownloadBcBetaReportBase64(response.data?.base64string);
        downloadBcBetaReport(response.data?.base64string);
      },
    });
  };

  // Download BcBeta Rows Report
  const downloadBcBetaReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64 || downloadBcBetaReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Partner Communication Report.xlsx`);
  };

  // Download Past Communication Report
  const downloadPastCommunicationReport = (customization: ReportDetailsCustomisationRes) => {
    return useFetch<{ base64string: string }>(API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_HISTORY.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_HISTORY.failureMessage,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        customisation: customization,
        isIntegrated: actor.integration,
      },
      thenCallBack: (response) => {
        const { base64string } = response.data;
        if (base64string) {
          const excelData = Buffer.from(base64string, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, `${actor.name} Past Communication.xlsx`);
        }
      },
    });
  };

  // Bulk Close And Reset Tasks
  const closeAndResetTasks = async (taskDetails) => {
    await useFetch(API_ENDPOINTS.CLOSE_TICKET_AND_RESET_COMMUNICATION.url, "POST", {
      failureMessage: API_ENDPOINTS.CLOSE_TICKET_AND_RESET_COMMUNICATION.failureMessage,
      data: { taskData: taskDetails, companyId: companyId, branchCode: branchCode },
      showSuccessToast: true,
      thenCallBack: () => {
        listAllBusinessPartners();
      },
      catchCallBack: () => {
        listAllBusinessPartners();
      },
    });
  };

  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        minWidth: "600px",
        height: "auto",
        borderRadius: "8px",
        position: "sticky",
        top: "-25px",
        zIndex: 10,
      }}
    >
      {/* Top Area */}
      <Box
        className="space_between main_header"
        sx={{
          padding: "24px",
        }}
      >
        <Typography variant="h5">Ongoing Communication</Typography>

        <Box className="vertical_center_align" gap={2}>
          <NdAsyncButton
            sx={{ minWidth: "auto", ".MuiButton-startIcon": { mx: "auto" } }}
            buttonComponent={NdButtonOutlined}
            onClick={async () => {
              const customization = await GetReportCustomization();
              if (customization || customizationSetting)
                await getDownloadBcBetaReport(customization || customizationSetting);
            }}
            startIcon={<Download sx={{ mx: "auto", fontSize: "24px !important" }} />}
            loadingIcon={<CircularProgress color="inherit" size={24} />}
          />
          <NdButtonOutlined startIcon={<MoreVert />} onClick={handleClick}>
            More
          </NdButtonOutlined>
          {/* More Menu */}
          <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
            {/* Customize Report */}
            <MenuItem
              onClick={() => {
                setIsHistoryReport(false);
                setOpenDownloadReportDlg(true);

                handleClose();
              }}
            >
              Customize Report
            </MenuItem>
            {/* Manage Communication Type */}
            <MenuItem
              onClick={() => {
                setOpenEditCommsType(true);

                handleClose();
              }}
            >
              Manage Communication Type
            </MenuItem>
            {/* Download Past Report */}
            <MenuItem
              onClick={() => {
                setIsHistoryReport(true);
                setOpenDownloadReportDlg(true);

                handleClose();
              }}
            >
              Download Past Report
            </MenuItem>
            <Divider />
            {/* Close Task */}
            <MenuItem
              disabled={mailingRows.length < 1 || ASYNC_INTERVAL_OPs_IN_PROGRESS}
              onClick={() => {
                setShowReviewCloseAndResetTasksModal(true);
                handleClose();
              }}
              sx={{ color: (t) => t.palette.error.main }}
            >
              Close Task
            </MenuItem>
          </Menu>
          <NdButtonOutlined
            startIcon={isFilterApplied ? <FilterList /> : <FilterListOff />}
            onClick={() => {
              setManuallyOpened(true);
              setOpenFilter(true);
            }}
          >
            Filter
          </NdButtonOutlined>
          <NdButton
            variant="contained"
            startIcon={<MailOutline />}
            disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS || bulkEmailStatus?.isBulkEmailScheduled}
            onClick={() => setOpenBcNewMailing(true)}
          >
            Send New Mail
          </NdButton>
        </Box>
      </Box>

      {Object.keys(appliedFilters).length > 0 && (
        <Tabs value={false} onChange={() => null} variant="scrollable" scrollButtons="auto">
          <Stack direction="row" spacing={2} className="space_between" sx={{ px: 3 }}>
            <Box className="d_flex vertical_center_align">
              {Object.keys(appliedFilters).map((key) =>
                appliedFilters[key]?.length ? (
                  <Box sx={{ mr: 2 }} key={key}>
                    <FilterAutocomplete
                      filterName={key}
                      filteredValues={appliedFilters[key]}
                      setFilterObj={setFilterObj}
                      setReFilter={setReFilter}
                    />
                  </Box>
                ) : null
              )}
            </Box>
          </Stack>
        </Tabs>
      )}

      <Divider sx={{}} />

      {/* Bottom Area */}
      <TabsComponent raisedIssues={raisedIssues} />

      {/* Top More Menu Components */}
      <ManageCommunicationType
        open={openEditCommsType}
        setOpen={setOpenEditCommsType}
        companyId={companyId}
        branchCode={branchCode}
        listUserEmailTemplates={listUserEmailTemplates}
      />

      <DownloadReport
        open={openDownloadReportDlg}
        setOpen={setOpenDownloadReportDlg}
        downloadAction={async (customization) =>
          isHistoryReport
            ? await downloadPastCommunicationReport(customization)
            : await getDownloadBcBetaReport(customization)
        }
      />

      {showReviewCloseAndResetTasksModal && (
        <ReviewCloseAndResetTasksModal
          open={showReviewCloseAndResetTasksModal}
          setOpen={setShowReviewCloseAndResetTasksModal}
          selectedTasks={mailingRows}
          closeAndResetTasks={closeAndResetTasks}
          companyId={companyId}
          branchCode={branchCode}
          setClearRows={() => null} //TODO: Implement this
        />
      )}

      <BalanceConfirmationNewFilter
        allCategories={storeAllCategories.current}
        storeRowsDataBalanceConfirmation={storeRowsDataBcBeta}
        rowsDataBalanceConfirmation={rowsDataBcBeta}
        setRowsDataBalanceConfirmation={setRowsDataBcBeta}
        setAppliedFilters={setAppliedFilters as any}
        setIsFilterApplied={setIsFilterApplied}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        reFilter={reFilter}
        setReFilter={setReFilter}
        companyIdRef={companyIdRef}
        branchCodeRef={branchCodeRef}
      />
    </Paper>
  );
};

const TabsComponent = ({ raisedIssues }: { raisedIssues: AllIssues[] }) => {
  const {
    tabValue,
    setTabValue,
    tabChipLabel,
    setOpenRaiseReq,
    listAllBusinessPartnerRequest,
    listAllBusinessPartners,
    companyId,
    branchCode,
    isSyncingMailStatus,
    setIsSyncingMailStatus,
    setLastMailSyncStatusDate,
  } = useContext(BcNewContext);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const syncMail = async () => {
    setIsSyncingMailStatus(true);
    return useFetch(API_ENDPOINTS.SYNC_MAIL.url, "POST", {
      failureMessage: API_ENDPOINTS.SYNC_MAIL.failureMessage,
      showSuccessToast: true,
      thenCallBack: () => {
        setIntervalMailSyncStatus();
      },
    });
  };

  interface MailThreadRefreshState {
    isCompleted: boolean;
    isSuccessful: boolean;
    // errorList: ErrorDetailsFetchStatus[];
    lastSyncedAt: Date;
  }

  // Bulk Mail Status
  const getSyncMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<{ status: MailThreadRefreshState }>(API_ENDPOINTS.SYNC_MAIL_STATUS.url, "GET", {
        failureMessage: API_ENDPOINTS.SYNC_MAIL_STATUS.failureMessage,
        config: {
          params: {
            companyId: companyId || DEFAULT_COMPANY_ID,
            branchCode: branchCode || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data?.status?.isCompleted) {
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (newIntervalId !== null) {
              clearInterval(newIntervalId);
              newIntervalId = null;
            }
          }

          setLastMailSyncStatusDate(res.data.lastSyncedAt);
          if (res.data?.status?.isCompleted) {
            if (res.data?.emailResponseMessage) {
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }

            resolve({ isCompleted: true });

            listAllBusinessPartnerRequest();
            setIsSyncingMailStatus(false);
            // dispatch(updateIsSendingBulkMailsBCB(false));
          } else {
            setIsSyncingMailStatus(true);
            // dispatch(updateIsSendingBulkMailsBCB(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(newIntervalId);
          newIntervalId = null;
          setIsSyncingMailStatus(false);
          // dispatch(updateIsSendingBulkMailsBCB(false));
          reject();
        },
      });
    });
  };

  const setIntervalMailSyncStatus = () => {
    newIntervalId = setInterval(async () => {
      const { isCompleted } = await getSyncMailStatus();
      if (isCompleted) {
        listAllBusinessPartners();
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(newIntervalId);
      }
    }, 15_000);
  };

  return (
    <Box className="vertical_center_align" sx={{ pl: 1, pr: 2 }}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        sx={{
          minHeight: 44,
          "& .MuiTabs-indicator": {
            backgroundColor: "#531c4c",
          },
          "& .MuiChip-root": {
            color: "#fff",
            bgcolor: "#0009",
            px: "6.5px",
            py: 0,
          },
          "& .Mui-selected": {
            color: "#531c4c !important",
            "& .MuiChip-root": {
              bgcolor: "#531c4c",
            },
          },
        }}
      >
        {Object.entries(TABS_MAP).map(([key, { label }]) => (
          <Tab
            key={key}
            label={label}
            icon={<Chip size="small" label={tabChipLabel[key]} />}
            iconPosition="end"
            sx={{ textTransform: "none", minHeight: "auto" }}
          />
        ))}
      </Tabs>
      {/* Partner Requests Async Button */}
      <NdAsyncButton
        onClick={async () => {
          await listAllBusinessPartnerRequest();
          setOpenRaiseReq(true);
        }}
        buttonComponent={Button}
        loadingIcon={
          <span style={{ marginRight: -6 }}>
            <LoadingIcon loading={true} />
          </span>
        }
        startIcon={<PersonIcon />}
        endIcon={<Chip label={raisedIssues?.length || 0} color="warning" size="small" sx={{ borderRadius: "100px" }} />}
        color="warning"
        sx={{ ml: "auto" }}
      >
        Partner Issues
      </NdAsyncButton>
      <Tooltip title="Load Mail Replies" arrow placement="left">
        <IconButton color="info" onClick={syncMail} disabled={isSyncingMailStatus}>
          <CachedIcon className={isSyncingMailStatus ? "spin" : ""} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default AuditCommunication;
