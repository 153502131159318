import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import { Recon360Context } from "../Recon360";
import "./PreReconciliationEvaluation.scss";

const PostReconciliationEvaluation = () => {
  const { openPostReconciliationEvaluationModal, setOpenPostReconciliationEvaluationModal, postReconLedgerScoreData } =
    useContext(Recon360Context);

  const handleClose = () => {
    setOpenPostReconciliationEvaluationModal(false);
  };

  return (
    <div className="PostReconciliationEvaluation">
      <Dialog open={openPostReconciliationEvaluationModal} maxWidth={DIALOG_MAX_WIDTHS.MD} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">Post-Reconciliation Evaluation</div>
          <div className="vertical_center_align">
            <IconButton
              onClick={() => {
                handleClose();
              }}
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 2 }}>
          <Grid sx={{ mb: 2 }}>
            <Typography variant="subtitle2" className="list_of_issues_title">
              List of issues and their possible reason or solution
            </Typography>

            {/*  */}
            <Grid>
              {postReconLedgerScoreData?.map((block, blockIndex) => (
                <Grid key={blockIndex} sx={{ mt: 3 }}>
                  <Box
                    sx={{
                      border: "1px solid #d0d0d0",
                      borderRadius: 2,
                      backgroundColor: "#ffffff",
                      width: "100%",
                    }}
                  >
                    {/* Table Header */}
                    <Box
                      className="amount-table-header"
                      sx={{ display: "flex", border: "none", borderBottom: "1px solid #d0d0d0", borderRadius: "8px" }}
                    >
                      {block.headers?.map((header, headerIndex) => (
                        <Typography
                          key={headerIndex}
                          className="amount-table-cell fw_600"
                          sx={{
                            flex: 1,
                            fontWeight: 600,
                            p: 1,
                            minWidth: header.viewType === "longText" ? "500px" : "auto", // Apply min-width for longText
                          }}
                        >
                          {header.value}
                        </Typography>
                      ))}
                    </Box>

                    {/* Table Rows */}
                    {block.values?.map((row, rowIndex) => (
                      <Box key={rowIndex} className="amount-table-td" sx={{ mb: 0 }}>
                        {row?.map((value, valueIndex) => (
                          <Typography
                            key={valueIndex}
                            className="amount-table-cell"
                            sx={{
                              flex: 1,
                              p: 1,
                              minWidth: block.headers[valueIndex].viewType === "longText" ? "500px" : "auto", // Apply min-width for corresponding column
                            }}
                          >
                            {value}
                          </Typography>
                        ))}
                      </Box>
                    ))}

                    {/* Messages as Alerts */}
                    {/* Messages */}
                    <Box sx={{ mt: 2 }}>
                      <Alert severity="warning" sx={{ borderRadius: "8px" }}>
                        <AlertTitle>{block.messages.header}</AlertTitle>
                        {block.messages.subtexts?.length > 0 && (
                          <ul style={{ margin: 0, paddingLeft: "20px", listStyleType: "disc" }}>
                            {block.messages.subtexts?.map((message, index) => (
                              <li key={index}>{message}</li>
                            ))}
                          </ul>
                        )}
                      </Alert>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PostReconciliationEvaluation;
