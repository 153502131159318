import { Breakpoint } from "@mui/system"; // Import Breakpoint type

export const DIALOG_MAX_WIDTHS: Record<string, Breakpoint | false> = {
  XS: "xs",
  SM: "sm",
  MD: "md",
  LG: "lg",
  XL: "xl",
  NONE: false, // If you ever need to disable maxWidth
};
