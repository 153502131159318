/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateRange, Settings } from "@mui/icons-material";
import { Box, Button, Chip, IconButton, MenuItem, Popover, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import FormatDate from "src/Utils/DateUtils";
import { BcBetaContext } from "../../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { PartnerCommsContext } from "../../../Context/PartnerCommunicationContext";
import { AutoReminder } from "../../PartnerCommsInterfaces";
import { DateCalendarServerDay } from "../CommonComponents";
import SetupNewReminder from "./SetupNewReminder";

const SetupAutoReminder = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [autoReminders, setAutoReminders] = useState<AutoReminder[]>([]);
  const { selectedAutoReminder, setSelectedAutoReminder } = useContext(PartnerCommsContext);
  const { branchCode, companyId, listUserEmailTemplates } = useContext(BcBetaContext);

  const startDate = !selectedAutoReminder?.reminderDates
    ? "-"
    : FormatDate(selectedAutoReminder?.reminderDates?.[0]).toString();

  const endDate = !selectedAutoReminder?.reminderDates
    ? "-"
    : FormatDate(selectedAutoReminder?.reminderDates?.[selectedAutoReminder?.reminderDates?.length - 1]).toString();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleViewDatesBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  const ListAllAutoReminders = async () => {
    return useFetch<{ details: AutoReminder[] }>(API_ENDPOINTS.LIST_ALL_AUTO_REMINDER_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_AUTO_REMINDER_TEMPLATES.failureMessage,
      thenCallBack: (res) => {
        if (res.data?.details) {
          setAutoReminders(res.data?.details || []);
        }
      },
    });
  };

  useEffect(() => {
    ListAllAutoReminders();

    return () => {
      setSelectedAutoReminder(null);
    };
  }, []);

  return (
    <>
      <Stack sx={{ bgcolor: "#fff", borderRadius: 1, ".MuiInputBase-root": { borderRadius: 1 } }}>
        {/* Header */}
        <Box
          className="header_bar space_between"
          alignItems="center"
          p={2}
          sx={{ borderBottom: 1, borderColor: "#dedede", borderRadius: "4px 4px 0 0" }}
        >
          <Typography variant="h6" fontWeight={600}>
            Setup Auto-Reminder
          </Typography>
          <IconButton size="small" onClick={async () => {}}>
            <Settings />
          </IconButton>
        </Box>
        {/* Main */}
        <Box padding={2}>
          <Typography variant="subtitle2" color="textSecondary" marginBottom={1}>
            Select Auto-Reminder
          </Typography>
          <Box className="d_flex" gap={2}>
            <TextField
              select
              fullWidth
              size="small"
              label="Select"
              value={selectedAutoReminder?.id || ""}
              variant="outlined"
              SelectProps={{
                renderValue: (value: Partial<AutoReminder>) => {
                  const filteredAutoReminder = autoReminders.filter((item) => item.id === Number(value));

                  if (filteredAutoReminder.length === 0) return "";

                  return filteredAutoReminder[0].name || filteredAutoReminder[0].autoReminderName;
                },
              }}
              onChange={async (e) => {
                const filteredAutoReminder = autoReminders.filter((item) => item.id === Number(e.target.value));

                setSelectedAutoReminder(filteredAutoReminder.length > 0 ? filteredAutoReminder[0] : null);
              }}
            >
              {autoReminders.length > 0 &&
                autoReminders.map((item) => (
                  <MenuItem className="d_flex" key={item.id} value={item.id}>
                    {item.name || item.autoReminderName}
                  </MenuItem>
                ))}
              <MenuItem value="">Select Template</MenuItem>
            </TextField>
            <Button
              variant="text"
              color="primary"
              sx={{ minWidth: 230 }}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              Create new auto-reminder
            </Button>
          </Box>
        </Box>
        {/* Reminder Dates Components */}
        <Box padding={2}>
          <Typography variant="subtitle2" color="textSecondary" marginBottom={1}>
            Dates for reminder
          </Typography>
          <Box className="d_flex" gap={2} alignItems="center">
            <Chip label={`First reminder date: ${startDate}`} variant="filled" sx={{ span: { font: "inherit" } }} />
            <Chip label={`Last reminder date: ${endDate}`} variant="filled" sx={{ span: { font: "inherit" } }} />
            <Button variant="text" color="primary" startIcon={<DateRange />} onClick={handleViewDatesBtnClick}>
              View all dates
            </Button>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <DateCalendarServerDay allHighlightedDays={selectedAutoReminder?.reminderDates || []} />
            </Popover>
          </Box>
        </Box>
      </Stack>
      <SetupNewReminder
        open={openDialog}
        setOpen={setOpenDialog}
        afterClose={async () => {
          await ListAllAutoReminders();
        }}
        branchCode={branchCode}
        companyId={companyId}
        listUserEmailTemplates={listUserEmailTemplates}
      />
    </>
  );
};

export default SetupAutoReminder;
