import { Close } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import React, { useContext } from "react";
import { toast } from "react-toastify";
import { DIALOG_MAX_WIDTHS } from "src/Components/Common/Modal/ModalWidth";
import CustomToast from "src/Components/CustomToast";
import { ledgerUploadName } from "src/Utils/Recon/Recon360/Constants";
import { uiLoggerNamesCBBreakdown } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import content_copy from "../../../../Graphics/AutomatedRecon/content_copy.svg";
import "../../../../styleguid.scss";
import { Recon360Context } from "../../Recon360";
import "./SumOfEntriesModal.scss";

export const Typography = ({ content = "Typography", variant, gutterBottom, className, bodyClassName }) => {
  return (
    <div className={`typography ${variant} gutter-bottom-${gutterBottom} ${className}`}>
      <div className={`body ${bodyClassName}`}>{content}</div>
    </div>
  );
};

const SumOfEntriesModal = ({
  openSumOfEntriesModal,
  setOpenSumOfEntriesModal,
  whichSide,
  missingEntryNumbers,
  name,
}) => {
  const { businessPartnerSelectedRef, companyId, branchCode } = useContext(Recon360Context);

  const handleClose = () => {
    setOpenSumOfEntriesModal(false);
  };

  const handleCopy = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      toast.success(<CustomToast message="Row Numbers copied to clipboard" />);
    });
  };
  function formatRanges(entries) {
    if (!entries.length) return "";

    const sortedEntries = [...entries].sort((a, b) => a - b); // Sort entries just in case
    const ranges = [];
    let start = sortedEntries[0];
    let end = sortedEntries[0];

    for (let i = 1; i < sortedEntries.length; i++) {
      if (sortedEntries[i] === end + 1) {
        end = sortedEntries[i];
      } else {
        if (start === end) {
          ranges.push(`${start}`);
        } else {
          ranges.push(`${start}-${end}`);
        }
        start = sortedEntries[i];
        end = sortedEntries[i];
      }
    }

    // Push the last range or single number
    if (start === end) {
      ranges.push(`${start}`);
    } else {
      ranges.push(`${start}-${end}`);
    }

    return ranges.join(", ");
  }

  return (
    <div>
      <Dialog open={openSumOfEntriesModal} maxWidth={DIALOG_MAX_WIDTHS.SM} fullWidth>
        <DialogTitle className="dialog-title">
          <div className="heading-m">Explanation of Sum of Entries</div>
          <div className="vertical_center_align">
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0 }}>
          <div className="frame">
            <div className="alert">
              <div className="icon-container">
                <InfoOutlinedIcon className="info-outlined" style={{ color: "#0288D1" }} />
              </div>
              <div className="text">
                <p className="description">
                  We have calculated the opening balance using the sum of your ledger entries and the closing balance.
                  In case you feel there is some discrepancy, kindly consider re-uploading the ledger and checking the
                  configurations.
                </p>
              </div>
            </div>
            <Typography
              bodyClassName="design-component-instance-node"
              className="typography-instance"
              content="Following are details of the entries not read by the tool:"
              gutterBottom={false}
              variant="body-1"
            />
            {Object.entries(missingEntryNumbers)?.map(([fileName, sheets]) => (
              <Grid container xs={12} key={fileName}>
                <Grid item xs={12} className="d_flex">
                  <Grid item xs={6}>
                    <Typography
                      bodyClassName="design-component-instance-node"
                      className="typography-instance"
                      content={`${whichSide} - ${fileName}`}
                      gutterBottom={false}
                      variant="subtitle-2"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      bodyClassName="design-component-instance-node"
                      className="typography-5"
                      content="Row Number(s)"
                      gutterBottom={false}
                      variant="subtitle-2"
                    />
                  </Grid>
                </Grid>

                {Object.entries(sheets).map(([sheetName, { missingEntries }]) => (
                  <Grid item xs={12} className="d_flex" key={sheetName}>
                    <Grid item xs={6}>
                      <div className="d_flex">
                        <Typography
                          bodyClassName="typography-3"
                          className="typography-2"
                          content={`Sheet: \u00A0`}
                          gutterBottom={false}
                          variant="body-2"
                        />
                        <Typography
                          bodyClassName="typography-3"
                          className="typography-4"
                          content={sheetName}
                          gutterBottom={false}
                          variant="body-2"
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <p className="p d_flex" key={sheetName}>
                        <img
                          className="content-copy mr_4"
                          alt="Content copy"
                          src={content_copy}
                          onClick={() => {
                            uiLogger(
                              name === ledgerUploadName.ownSide
                                ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_KNOW_MORE_COPY_CLICK.functionName
                                : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_KNOW_MORE_COPY_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message:
                                  name === ledgerUploadName.ownSide
                                    ? uiLoggerNamesCBBreakdown.UI_RL_OWN_CB_DETAILS_KNOW_MORE_COPY_CLICK.message
                                    : uiLoggerNamesCBBreakdown.UI_RL_BP_CB_DETAILS_KNOW_MORE_COPY_CLICK.message,
                                businessPartnerId: businessPartnerSelectedRef.current,
                              }
                            );
                            handleCopy(missingEntries.join(", "));
                          }}
                          style={{ cursor: "pointer" }}
                        />
                        <span className="text-wrapper mr_4" style={{ fontWeight: 400, minWidth: "fit-content" }}>
                          {missingEntries.length} Rows:
                        </span>
                        <span className="span"> {formatRanges(missingEntries)}</span>
                      </p>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SumOfEntriesModal;
